/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");

const saveMockup = (mockup) =>
  httpClient
    .post(`/mockup`, { mockup }, { "Content-Type": "application/json" })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

//CATALOGV1
// const getMockup = (material, store, mockups) =>
//   httpClient
//     .get(
//       `/mockup/${material}?store=${store}&mockups[]=${mockups.join(
//         "&mockups[]="
//       )}`,
//       {
//         "Content-Type": "application/json",
//       }
//     )
//     .then((response) => response.data)
//     .catch((error) => Promise.reject(error));

//CATALOGV2
const getMockup = (material, store, mockups) =>
  httpClient
    .get(
      `public/mockups/${material}?store=${store}&mockups[]=${mockups.join(
        "&mockups[]="
      )}`,
      {
        "Content-Type": "application/json",
      }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const destroyMockup = ({ id }) =>
  httpClient
    .delete(`/mockup/${id}`, {
      headers: {
        Authorization: "***",
      },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export { saveMockup, getMockup, destroyMockup };
