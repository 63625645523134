<template>
  <div
    v-if="!element.hideInput"
    class="text-component"
    :data-label="labelDefault"
    :data-question="element.name"
    :style="{
      display: labelDefault === 'Answer AI' ? 'none' : '',
    }"
  >
    <h3 class="text-component__label" :data-label="$t('labelText')" />

    <div v-if="!validate" class="text-component--alert">
      {{ $t("hintInputText") }}
    </div>

    <div class="text-component__input">
      <div class="text-component__input-container">
        <input-component
          v-if="!colorOpen"
          class="text-component__input-element"
          :element="element"
          :disabled="removedCustomization"
          @update="update"
        />

        <!-- <div class="text-component__input-check" @click="disable">
          <div v-if="emptyInputError" class="text-component__input-message">
            Disable required input
          </div>
          <div class="text-component__input-label">
            <icon-check v-if="!element.disabled" />
          </div>
        </div> -->
      </div>

      <colors-component
        v-if="showColors"
        :colors="element.colors"
        :color="element.color"
        @color="setNewColor"
      />

      <fonts-component v-if="showFonts" :components="element.components" />

      <div class="text-component__ai-actions">
        <select
          v-if="element.showDiscoverAiBtn && $i18n.locale !== 'ptbr'"
          class="text-component__language"
          id="language"
        >
          <option value="" selected>🌐 {{ $t("select_a_language") }}</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Italian">Italian</option>
          <option value="Spanish">Spanish</option>
          <option value="English">English</option>
          <option value="Portuguese">Portuguese</option>
        </select>

        <button
          :disabled="loadingAnswer"
          class="text-component__action-btn text-component__action-btn--ai"
          v-if="element.showDiscoverAiBtn"
          @click="generateAnswer"
        >
          <span v-if="loadingAnswer">
            <three-dots-loading />
          </span>
          <span v-else>
            {{ $t("discover_name") }}
          </span>
        </button>
        <button
          type="button"
          v-else-if="labelDefault !== 'Question AI'"
          :class="[
            'text-component__action-btn',
            {
              'text-component__action-btn--active': removedCustomization,
            },
          ]"
          @click="removePlaceholder"
        >
          <span
            v-if="removedCustomization"
            class="text-component__action-btn-icon"
          >
            <icon-cross-pencil />
          </span>

          {{ $t("removeCustomization") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "./input-component/InputComponent.vue";
import ColorsComponent from "./colors-component/ColorsComponent.vue";
import FontsComponent from "./fonts-component/FontsComponent.vue";
import ThreeDotsLoading from "../three-dots-loading/ThreeDotsLoading.vue";
import IconCrossPencil from "@gocasebr/go-shared/dist/cross-pencil/CrossPencil";
import { getAnswer } from "@/api/catalog-api/ai/openai.api.js";

export default {
  name: "TextComponent",
  components: {
    InputComponent,
    ColorsComponent,
    FontsComponent,
    IconCrossPencil,
    ThreeDotsLoading,
  },
  props: {
    element: Object,
    elementsText: Array,
    language: String,
  },
  data() {
    return {
      colorOpen: false,
      removedCustomization: false,
      loadingAnswer: false,
    };
  },
  computed: {
    labelDefault() {
      return this.element.label.values.default;
    },
    validate() {
      const values = [
        "sem texto",
        "sem nome",
        "tira o nome",
        "without name",
        "without text",
      ];
      if (!this.element || !this.element.value) return true;
      return !values.includes(this.element.value.toLowerCase());
    },
    fontActive() {
      return this.element.components.find((el) => el.active);
    },
    showColors() {
      const { element } = this;
      return element.colors.length && element.colors.length > 1;
    },
    showFonts() {
      const { element } = this;
      return element.components.length && element.components.length > 1;
    },
    errors() {
      const errors = this.$store.getters.elementErrors(this.element);
      return errors.map(({ error }) => error);
    },
    emptyInputError() {
      const { errors, EMPTY_INPUT_ERROR } = this;
      const hasError =
        errors && errors.length && errors.includes(EMPTY_INPUT_ERROR);
      return !!hasError;
    },
  },
  methods: {
    setNewColor(color) {
      this.element.color = color;
      if (this.labelDefault === "Question AI") {
        this.$emit("newAiColor", color);
      }
    },
    async generateAnswer() {
      this.loadingAnswer = true;

      const event = new Event("input");
      const inputEl = document.querySelector("[data-label='Answer AI'] input");
      inputEl.value = this.$t("ai_answer_generating");
      inputEl.dispatchEvent(event);

      const questionDiv = "[data-label='Question AI']";

      const questionInputs = document.querySelectorAll(`${questionDiv} input`);
      const questionValue = Array.from(questionInputs)
        .map((qi) =>
          qi.closest(questionDiv).dataset.question.replace("<name>", qi.value)
        )
        .join("");

      const langEl = document.querySelector("#language");
      let selectedLang = langEl?.options?.[langEl.selectedIndex]?.value;

      const finalQuestion = `${questionValue} ${
        selectedLang ? " Give me the result in " + selectedLang + "." : ""
      }`;
      let textPos = 0;

      const storedAiKey = `ai-${finalQuestion}`.toLowerCase();

      let answer = window.sessionStorage.getItem(storedAiKey);

      if (!answer) {
        const { output, cached } = await getAnswer(finalQuestion);
        answer = output[0];
        window.sessionStorage.setItem(storedAiKey, answer);
        window.sessionStorage.setItem(`${storedAiKey}-cached`, cached);
      }

      inputEl.value = "";
      inputEl.dispatchEvent(event);
      const typingTextInterval = setInterval(() => {
        inputEl.value = inputEl.value + answer[textPos];
        inputEl.dispatchEvent(event);
        textPos += 1;
        if (textPos >= answer.length) {
          clearInterval(typingTextInterval);
          this.loadingAnswer = false;
        }
      }, 50);
    },
    // disable() {
    //   this.$store.dispatch("removeElementError", {
    //     element: this.element,
    //     error: "emptyInput",
    //   });
    //   this.element.disabled = !this.element.disabled;
    //   this.removePlaceholder();
    // },
    changeFont() {
      let fontIndex = this.element.components.indexOf(this.fontActive);
      fontIndex++;
      if (fontIndex > this.element.components.length - 1) fontIndex = 0;
      this.element.components.forEach((el, i) => (el.active = i === fontIndex));
      this.update();
    },
    removePlaceholder() {
      this.element.placeholder = "";
      this.element.placeholderPt = "";
      this.element.placeholderEn = "";
      this.element.value = "";
      this.removedCustomization = !this.removedCustomization;
      this.update();
    },
    update() {
      if (
        !!this.elementsText &&
        !!this.element.elementsReplaced &&
        this.element.elementsReplaced.length
      ) {
        this.elementsText.forEach((el) => {
          if (
            this.element.elementsReplaced.find((er) => er === el.identifier)
          ) {
            el.maxlen = this.element.maxlen;
            el.placeholder = this.element.placeholder;
            el.placeholderEn = this.element.placeholderEn;
            el.placeholderPt = this.element.placeholderPt;
            el.value = this.element.value;
            el.runUpdate = true;
            // el.hideInput = true;
          } else {
            // el.hideInput = false;
          }
        });
      }

      this.element.runUpdate = true;
    },
    closeChooser() {
      this.$emit("cleanRenderComponent");
    },
  },
  mounted() {
    // this.element.disabled = false;
    this.element.placeholder = this.language.includes("pt")
      ? this.element.placeholderPt
      : this.element.placeholderEn;

    this.update();
  },
};
</script>

<style lang="scss" scoped>
.text-component {
  &--alert {
    font-size: 14px;
    border-radius: 4px;
    background-color: crimson;
    color: #fff;
    padding: 6px;
    margin: 6px 0;
  }

  &__input {
    display: grid;
    gap: 10px;

    &-container {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 6px;
    }

    &-check {
      width: 36px;
      height: 36px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &-label {
      border: 1px solid #afafaf;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 100%;

      & svg {
        width: 50%;
        max-width: 26px;
      }
    }

    &-message {
      position: absolute;
      background: #f4f4f4;
      box-shadow: 0px 0px 20px 4px #c2c2c2;
      border-radius: 4px;
      padding: 4px 6px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      animation: fade 200ms;
      right: 100%;
      margin-right: 14px;

      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top: 0;
        border-bottom: 10px solid #f4f4f4;
        margin-left: -3px;
        left: 100%;
        transform-origin: center center;
        transform: rotate(90deg);
      }
    }

    @keyframes fade {
      0% {
        opacity: 0;
        transform: translateX(40px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &__action-btn {
    position: relative;
    text-transform: uppercase;
    font-size: 11px;
    padding: 5px 10px;
    border: 1px solid #d2d2d6;
    height: 30px;
    border-radius: 4px;
    color: #323236;
    fill: #323236;
    background-color: transparent;
    transition: background-color 0.1s ease-in-out;
    width: 186px;
    cursor: pointer;
    display: grid;
    align-items: center;
    gap: 6px;

    &--ai {
      background: #f37053;
      color: white;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;
      padding: 8px 14px;
      flex: 3;
    }

    &-icon {
      width: 15px;
      padding-bottom: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: margin 0.5s ease;

      & svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scale(0.8) translate(0, -1px);
        transition: transform 0.5s ease, position 0.5s ease;
      }
    }

    transition: border 0.5s ease;

    &--active {
      border: 1px solid #f37053;
      grid-template-columns: auto 1fr;

      svg {
        color: #f37053;
      }
    }
  }

  &__ai-actions {
    display: flex;
    justify-content: center;
  }

  &__language {
    width: 100%;
    color: #333;
    font-family: "Montserrat", helvetica neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    outline: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: 38px;
    padding: 8px 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #afafaf;
    border-radius: 4px;
    text-align: center;
    width: 186px;
    margin: 0 auto;
    flex: 1.4;
    margin-right: 5px;

    &:focus {
      border-color: #ffd8d0;
      -webkit-box-shadow: 0 0 5px #ffc4dd;
      box-shadow: 0 0 5px #ffc4dd;
    }
    &:hover {
      border-color: #ffd8d0;
    }
  }

  &__label {
    font-family: sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: #666;
    text-transform: uppercase;
    padding: 0 10px;
    margin: 0 0 6px 0;
    height: 14px;
    line-height: 14px;
    width: 100%;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #ddd;
      left: 0;
      top: 50%;
      position: absolute;
    }

    &:after {
      content: attr(data-label);
      display: block;
      position: absolute;
      background-color: #fff;
      padding: 0 4px;
      top: 0;
    }
  }
}
</style>
