<template>
  <picker-items-list-component
    :opened="true"
    :showed-items-length="showedItemsLength"
    @click="() => $emit('change', { component })"
  >
    <template v-slot:container>
      <template v-if="currentComponent">
        <div class="show-more-button" @click="click()">
          <div class="show-more-button__content">
            <span class="show-more-button__label">
              <arrow-left />
            </span>
          </div>
        </div>
        <picker-item-component
          v-for="image in currentComponent.images"
          :key="`${image.key}-1`"
          :image="image"
          :component="currentComponent"
          @click="(v) => $emit('pickItem', v)"
        />
      </template>

      <template v-else>
        <picker-item-component
          v-for="component in availableComponents"
          :key="imageActive(component).key"
          :image="imageActive(component)"
          :component="component"
          :label="label(component.label.values)"
          @click="pickComponent"
        />
      </template>
    </template>
  </picker-items-list-component>
</template>

<script>
import PickerItemComponent from "@/components/picker-component/picker-item-component/PickerItemComponent.vue";
import PickerItemsListComponent from "@/components/picker-component/picker-items-list-component/PickerItemsListComponent.vue";
import ArrowLeft from "@/components/icons/ArrowLeft.vue";

import newPickersUsability from "@/mixins/new-pickers-usability.js";

export default {
  mixins: [newPickersUsability],
  components: {
    PickerItemComponent,
    PickerItemsListComponent,
    ArrowLeft,
  },
  props: {
    availableComponents: Array,
    showedItemsLength: Number,
    label: Function,
    openedComponent: Object,
  },
  data() {
    return {
      currentComponent: null,
    };
  },
  watch: {
    openedComponent() {},
  },
  methods: {
    click() {
      this.showLess();
      this.currentComponent = null;
      this.$emit("label", "");
    },
    currentComponentLabel() {
      return this.currentComponent
        ? this.label(this.currentComponent.label.values)
        : "";
    },
    pickComponent({ component, image }) {
      this.currentComponent = component;
      this.$emit("change", component);
      this.$emit("label", this.currentComponentLabel());
      this.$emit("pickItem", { component, image });
      this.showMore();
    },
    imageActive({ images }) {
      const imageActive = images.find(({ active, show }) => active && show);
      return imageActive || images[0];
    },
  },
};
</script>

<style lang="scss" scoped>
$gap: 6px;
$size: calc(100% - 2px);

.show-more-button {
  position: relative;
  width: $size;
  padding-bottom: $size;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
  margin-top: 4px;
  color: #222;
  text-align: center;
  box-sizing: border-box;
  box-shadow: inset 0 -3px 0 rgb(0 0 0 / 10%);
  background-color: rgba($color: #000000, $alpha: 0.05);

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 12px;
    text-align: center;
    gap: 8px;
  }

  &__label {
    text-transform: uppercase;
    font-size: 10px;
  }
}
</style>
