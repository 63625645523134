<template>
  <ul class="fonts-component">
    <li
      class="fonts-component__item"
      :class="{ 'fonts-component__item--active': f.active }"
      @click="select(f)"
      v-for="(f, i) in components"
      :key="i"
      :style="{ fontFamily: f.identifier }"
    >
      A
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    components: Array,
  },
  methods: {
    select(font) {
      this.components.forEach((f) => (f.active = f === font));
    },
  },
};
</script>

<style lang="scss" scoped>
.fonts-component {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    line-height: 26px;
    overflow: hidden;
    width: 28px;
    height: 28px;
    transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
    border: 1px solid #afafaf;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin: 2px;

    &--active {
      box-shadow: 0 0 0 2px #f37053;
      border: 2px solid #fff;
    }
  }
}
</style>
