import Vue from "vue";
import Vuex from "vuex";

import StoreAlert from "./store-alert/store-alert.js";
import StoreElement from "./store-element/store-element.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...StoreAlert.state,
    ...StoreElement.state,
    removingBg: false,
  },
  mutations: {
    ...StoreAlert.mutations,
    ...StoreElement.mutations,
    removingBg(state, removing = false) {
      state.removingBg = removing;
    },
  },
  actions: {
    ...StoreAlert.actions,
    ...StoreElement.actions,
    removingBg({ commit }, removing) {
      commit("removingBg", removing);
    },
  },
  getters: {
    ...StoreAlert.getters,
    ...StoreElement.getters,
  },
});
