<template>
  <component-text
    v-if="font"
    :key="font.id"
    :font="font"
    :element="element"
    :preview-context="previewContext"
    :showTextBorder="showTextBorder"
  />
</template>

<script>
import ComponentText from "./component-text/ComponentText.vue";

export default {
  components: { ComponentText },
  props: {
    element: Object,
    previewContext: Object,
    showTextBorder: Boolean,
  },
  computed: {
    font() {
      return this.element.components.find((f) => f.active);
    },
  },
};
</script>

<style lang="scss" scoped></style>
