<template>
  <div
    :class="[
      'component-image',
      {
        'component-image--selected':
          $store &&
          !!$store.state.currentElement &&
          element.id === $store.state.currentElement.id,
        'selected-component':
          $store &&
          !!$store.state.currentElement &&
          element.id === $store.state.currentElement.id,
      },
    ]"
    :data-component-id="element.id"
    @click="selectElement"
    :style="groupStyle"
    v-if="image"
  >
    <img class="component-image__item" :src="image.src" :style="imageStyle" />
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    previewContext: Object,
    image: Object,
    width: Number,
    height: Number,
  },
  computed: {
    calcs() {
      const { element, width, height, previewContext, image } = this;
      if (!element || !width || !height || !previewContext) return;
      const { scaleX, scaleY } = this.getScales(
        element,
        width,
        height,
        previewContext
      );

      const { boxWidth, boxHeight } = this.boxSize(
        width * scaleX,
        height * scaleY,
        element.rotation
      );

      // eslint-disable-next-line
      return {
        image,
        element,
        width,
        height,
        previewContext,
        scaleX,
        scaleY,
        boxWidth,
        boxHeight,
      };
    },
    groupStyle() {
      const { element, boxWidth, boxHeight, previewContext } = this.calcs;

      let left, right;
      switch (element.alignX) {
        case "center":
          left = previewContext.width / 2 - boxWidth / 2;
          left += element.mx * previewContext.width;
          break;
        case "left":
          left = 0;
          left += element.mx * previewContext.width;
          break;
        case "right":
          right = 0;
          right -= element.mx * previewContext.width;
          break;
      }

      let top, bottom;
      switch (element.alignY) {
        case "middle":
          top = previewContext.height / 2 - boxHeight / 2;
          top += element.my * previewContext.height;
          break;
        case "top":
          top = 0;
          top += element.my * previewContext.height;
          break;
        case "bottom":
          bottom = 0;
          bottom -= element.my * previewContext.height;
          break;
      }

      return {
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        top: `${top + "px"}`,
        bottom: `${bottom + "px"}`,
        left: `${left + "px"}`,
        right: `${right + "px"}`,
      };
    },
    imageStyle() {
      const { element, width, height, scaleX, scaleY } = this.calcs;
      return {
        width: `${width * scaleX}px`,
        height: `${height * scaleY}px`,
        transform: `rotate(${element.rotation}deg)`,
      };
    },
  },
  methods: {
    selectElement() {
      this.$store.dispatch("setCurrentElement", {
        element: this.element,
      });
    },
    boxSize(width, height, rotation) {
      const cos = Math.cos(rotation * (Math.PI / 180));
      const sin = Math.sin(rotation * (Math.PI / 180));

      return {
        boxWidth: Math.ceil(Math.abs(width * cos) + Math.abs(height * sin)),
        boxHeight: Math.ceil(Math.abs(width * sin) + Math.abs(height * cos)),
      };
    },
    getScales(element, width, height, previewContext) {
      let scaleWidth = 1,
        scaleHeight = 1;
      switch (element.applyScale) {
        case "default":
          scaleWidth = previewContext.width / width;
          scaleHeight = scaleWidth;
          break;
        case "context":
          scaleWidth = previewContext.width / width;
          scaleHeight = previewContext.height / height;
          break;
        case "real":
          scaleWidth = 1;
          scaleHeight = 1;
          break;
      }
      if (width === height) scaleHeight = scaleWidth;

      return {
        scaleX: scaleWidth * element.scaleX,
        scaleY: scaleHeight * element.scaleY,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.component-image {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-image--selected .component-image__item {
  border: 5px dotted red;
}
</style>
