<template>
  <div class="colors-component">
    <ul class="colors-component__list">
      <li
        class="colors-component__list-item"
        :class="{
          'colors-component__list-item--active': c === color,
        }"
        @click="$emit('color', c)"
        v-for="(c, i) in colors"
        :key="i"
        :style="{ background: c, color: c }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    colors: Array,
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.colors-component {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0 2px 0px 2px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 22px);
    align-items: center;
    gap: 6px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 18px;
      line-height: 18px;
      overflow: hidden;
      width: 22px;
      height: 22px;
      transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 100%;
      cursor: pointer;
      margin: 3px 0;

      &--active {
        box-shadow: 0 0 0 2px;
        border: 2px solid #fff;
      }
    }
  }
}
</style>
