<template>
  <div class="picker-item-component">
    <span class="picker-item-component__label" v-if="label">{{ label }}</span>
    <div
      :class="['e-picker__item', { active: image.active && image.show }]"
      @click="image.disabled ? null : pickItem()"
    >
      <div
        class="item__image"
        :style="{ backgroundImage: `url(${imageUrl(image.thumb)}` }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    component: Object,
    image: Object,
    label: String,
  },
  methods: {
    imageUrl(url) {
      return url.replace("http://", "https://");
    },
    pickItem() {
      this.$emit("click", {
        image: this.image,
        component: this.component,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$size: calc(100% - 2px);

.picker-item-component {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  &__label {
    color: #666;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 11px;
    text-align: center;
  }
}

.e-picker__item {
  width: $size;
  border-radius: 6px;
  padding-bottom: $size;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid #e1e1ea;
  margin: 2px;

  .item__image {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.active {
    border-color: #f37053;
  }
}
</style>
