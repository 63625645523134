<template>
  <div :class="['picker-items-list']" :style="pickerItemsListStyles">
    <div
      v-if="showButton && opened"
      @click="click('show-less')"
      class="show-more-button"
    >
      <div class="show-more-button__content">
        <template>
          <span class="show-more-button__label">
            <arrow-left />
          </span>
        </template>
      </div>
    </div>
    <slot name="container" :opened="opened" />

    <div
      v-if="showButton && !opened"
      @click="click('show-more')"
      class="show-more-button"
    >
      <div class="show-more-button__content">
        <template>
          <span class="show-more-button__label show-more-button__label--more">
            <arrow-left />
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import newPickersUsability from "@/mixins/new-pickers-usability.js";
import ArrowLeft from "@/components/icons/ArrowLeft.vue";

export default {
  components: { ArrowLeft },
  mixins: [newPickersUsability],
  props: {
    showButton: Boolean,
    showedItemsLength: Number,
    opened: Boolean,
  },
  computed: {
    pickerItemsListStyles() {
      const { showedItemsLength } = this;
      const columns = `calc((100% - (6px * ${
        showedItemsLength - 1
      })) / ${showedItemsLength})`;

      return {
        gridTemplateColumns: `repeat(auto-fit, ${columns})`,
      };
    },
  },
  methods: {
    click(code) {
      this.$emit("click");

      if (code === "show-more") {
        this.showMore();
      }

      if (code === "show-less") {
        this.showLess();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$gap: 6px;
$size: calc(100% - 2px);

.picker-items-list {
  display: grid;
  gap: $gap;
  overflow: hidden;

  &--active {
    transition: height 0.5s ease;
  }

  .show-more-button {
    position: relative;
    width: $size;
    padding-bottom: $size;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
    margin-top: 4px;
    color: #222;
    text-align: center;
    box-sizing: border-box;
    box-shadow: inset 0 -3px 0 rgb(0 0 0 / 10%);
    background-color: rgba($color: #000000, $alpha: 0.05);

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 12px;
      text-align: center;
      gap: 8px;
    }

    &__label {
      text-transform: uppercase;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        fill: #f37053;
        width: 20px;
        margin-top: 0px;
        transform: rotate(90deg);
      }

      &--more {
        > svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
</style>
