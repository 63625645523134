<template>
  <div class="editor__render" v-if="!!canvas">
    <div class="editor__render-container">
      <div class="editor__render-container__cropper" :style="canvasStyle">
        <figure
          ref="imageCurrent"
          style="margin: 0; position: absolute; transition: 0.1s"
          :style="!!filter ? filter.style : {}"
          v-pan="onPan"
        >
          <div style="width: 100%, height: 100%; position: relative">
            <div
              v-for="(overlay, i) in !!filter && !!filter.overlays
                ? filter.overlays
                : []"
              :key="i"
              :style="{
                position: `absolute`,
                'mix-blend-mode': overlay.mixBlendMode,
                background: overlay.color,
                opacity: overlay.opacity,
                width: `100%`,
                height: `100%`,
              }"
            ></div>
            <img
              ref="imageElement"
              crossorigin="Anonymous"
              :src="imageCurrent"
              @load="adjustmentScale"
            />
          </div>
        </figure>

        <img
          ref="imageBefore"
          class="editor__render-container__mask"
          crossorigin="Anonymous"
          :src="imageBefore"
          :width="imageBeforeWidth"
          :height="imageBeforeHeight"
          draggable="false"
          onmousedown="return false"
          v-pan="onPan"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sizeSquare: Number,
    imageBeforeWidth: Number,
    imageBeforeHeight: Number,
    imageBeforeIsMask: Boolean,
    imageBeforePadding: Object,
    imageBefore: String,
    imageCurrent: String,
    filter: Object,
    setFlip: Object,
    setScale: Object,
    setRotation: Object,
  },
  data() {
    return {
      lastPosX: 0,
      lastPosY: 0,
      scaleX: 1,
      scaleY: 1,
      rotation: 0,
      canvas: null,
    };
  },
  computed: {
    generalScale() {
      const { imageBeforeWidth, imageBeforeHeight } = this;
      const max =
        imageBeforeHeight > imageBeforeWidth
          ? imageBeforeHeight
          : imageBeforeWidth;
      return this.sizeSquare / max;
    },
    canvasStyle() {
      const { imageBeforeWidth, imageBeforeHeight, generalScale } = this;
      return {
        width: `${imageBeforeWidth}px`,
        height: `${imageBeforeHeight}px`,
        transform: `scale(${generalScale})`,
      };
    },
  },
  watch: {
    setFlip: {
      handler(v) {
        if (v.value === "h") this.scaleX *= -1;
        if (v.value === "v") this.scaleY *= -1;
        this.refresh();
      },
      deep: true,
    },
    setScale: {
      handler(v) {
        let temp = Math.abs(this.scaleX);
        temp += v.value === "i" ? 0.1 : -0.1;

        if (this._zoomBoundFunc(temp)) {
          this.scaleX = this.scaleX < 0 ? -temp : temp;
          this.scaleY = this.scaleY < 0 ? -temp : temp;
        } else this.adjustmentScale();

        this.refresh();
      },
      deep: true,
    },
    setRotation: {
      handler(v) {
        if (v.value === "l") this.rotation -= 15;
        if (v.value === "r") this.rotation += 15;
        this.adjustmentScale();
        this.refresh();
      },
      deep: true,
    },
  },
  methods: {
    imageCurrentDimensions() {
      const img = this.$refs?.imageCurrent?.getBoundingClientRect();
      return !!img
        ? {
            width: img.width / this.generalScale,
            height: img.height / this.generalScale,
          }
        : null;
    },
    transform(element, posX, posY, degress, scaleX, scaleY) {
      element.style.left = posX ? `${posX}px` : "50%";
      element.style.top = posY ? `${posY}px` : "50%";
      element.style.transform = `translate(-50%, -50%) rotate(${degress}deg) scale(${scaleX}, ${scaleY})`;
    },
    refresh() {
      this.update();
      setTimeout(() => this.update(), 220);
    },
    update() {
      if (!!this.$refs?.imageCurrent) {
        this._dragBoundFuncFor(
          this.lastPosX,
          this.lastPosY,
          this.imageBeforeWidth,
          this.imageBeforeHeight,
          this.imageBeforePadding
        );
        this.transform(
          this.$refs.imageCurrent,
          this.lastPosX,
          this.lastPosY,
          this.rotation,
          this.scaleX,
          this.scaleY
        );
      }
    },
    onPan(ev) {
      let elem = this.$refs.imageCurrent;
      let posX = ev.deltaX + this.lastPosX;
      let posY = ev.deltaY + this.lastPosY;
      elem.style.transition = null;

      this.transform(elem, posX, posY, this.rotation, this.scaleX, this.scaleY);

      if (ev.isFinal) {
        this.lastPosX = posX;
        this.lastPosY = posY;
        elem.style.transition = "0.1s";
        this.update();
      }
    },
    cropper(callback) {
      const dimensions = this.imageCurrentDimensions();
      if (!dimensions) return;
      this.beforeDraw(
        {
          img: this.$refs.imageElement,
          width: dimensions.width,
          height: dimensions.height,
        },
        (canvasResponse) => {
          this.finalDraw(
            {
              maskSrc: this.$refs.imageBefore,
              imageSrc: canvasResponse,
              imageCenterWidth: dimensions.width / 2,
              imageCenterHeight: dimensions.height / 2,
              imageWidth: canvasResponse.width,
              imageHeight: canvasResponse.height,
              canvasWidth: this.imageBeforeWidth,
              canvasHeight: this.imageBeforeHeight,
            },
            callback
          );
        }
      );
    },
    beforeDraw({ img, width, height }, callback) {
      const { scaleX, scaleY, rotation, filter } = this;
      const c = this.canvas.cloneNode();
      c.width = width / Math.abs(scaleX);
      c.height = height / Math.abs(scaleY);
      const ctx = c.getContext("2d");

      ctx.translate(c.width / 2, c.height / 2);
      ctx.rotate(rotation * (Math.PI / 180));
      ctx.scale(scaleX < 0 ? -1 : 1, scaleY < 0 ? -1 : 1);

      // Apply Filters
      ctx.filter = filter?.value;

      // Draw Main Image
      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(img, -img.offsetWidth / 2, -img.offsetHeight / 2);

      // Apply Overlays
      filter?.overlays?.forEach((overlay) => {
        ctx.globalAlpha = overlay.opacity;
        ctx.fillStyle = overlay.color;
        ctx.globalCompositeOperation = overlay.mixBlendMode;
        ctx.fillRect(-c.width / 2, -c.height / 2, c.width, c.height);
      });

      callback(c);
    },
    finalDraw(
      {
        maskSrc,
        imageSrc,
        imageCenterWidth,
        imageCenterHeight,
        imageWidth,
        imageHeight,
        canvasWidth,
        canvasHeight,
      },
      callback
    ) {
      const c = this.canvas.cloneNode();
      const ctx = c.getContext("2d");
      c.width = canvasWidth;
      c.height = canvasHeight;

      // Image Settings
      const x = this.lastPosX - imageCenterWidth;
      const y = this.lastPosY - imageCenterHeight;
      const width = imageWidth * Math.abs(this.scaleX);
      const height = imageHeight * Math.abs(this.scaleY);

      if (this.imageBeforeIsMask) {
        // Draw mask image
        ctx.drawImage(maskSrc, 0, 0, c.width, c.height);
        // Apply cropper type
        ctx.globalCompositeOperation = "source-out";
        // Draw image current
        ctx.drawImage(imageSrc, x, y, width, height);
      } else {
        // Draw image current
        ctx.drawImage(imageSrc, x, y, width, height);
        // Draw mask image
        ctx.drawImage(maskSrc, 0, 0, c.width, c.height);
      }

      c.toBlob(callback, "image/png", 1);
    },
    adjustmentScale() {
      const el = this.imageCurrentDimensions();
      if (!el) return;
      el.width /= Math.abs(this.scaleX);
      el.height /= Math.abs(this.scaleY);

      const min = el.width < el.height ? el.width : el.height;
      this.scaleX = this.sizeSquare / min / this.generalScale;
      this.scaleY = this.scaleX;
      this.refresh();
    },
    _zoomBoundFunc(nScale) {
      // Zoom
      const { imageBeforeWidth, imageBeforeHeight, imageBeforePadding } = this;
      const { top, right, bottom, left } = imageBeforePadding;

      const availableW = imageBeforeWidth - imageBeforeWidth * (left + right);
      const availableH = imageBeforeHeight - imageBeforeHeight * (top + bottom);

      const el = this.imageCurrentDimensions();
      if (!el) return;
      el.width /= Math.abs(this.scaleX);
      el.height /= Math.abs(this.scaleY);
      el.width *= Math.abs(nScale);
      el.height *= Math.abs(nScale);

      return el.width >= availableW && el.height >= availableH;
    },
    _dragBoundFuncFor(x, y, width, height, padding) {
      const el = this.imageCurrentDimensions();
      if (!el) return;
      let elWidth = el.width / 2;
      let elHeight = el.height / 2;
      let limitW = width - elWidth;
      let limitH = height - elHeight;

      elWidth += width * padding.left;
      elHeight += height * padding.top;
      limitW -= width * padding.right;
      limitH -= height * padding.bottom;

      let newX = x;
      let newY = y;

      if (x > elWidth) newX = x > elWidth ? elWidth : x;
      else newX = x < limitW ? limitW : x;

      if (y > elHeight) newY = y > elHeight ? elHeight : y;
      else newY = y < limitH ? limitH : y;

      this.lastPosX = newX;
      this.lastPosY = newY;
    },
  },
  mounted() {
    this.canvas = document.createElement("canvas");
    this.$emit("cropper", this.cropper);
    this.refresh();
  },
  destroyed() {
    delete this.canvas;
  },
};
</script>

<style lang="scss" scoped>
.editor__render {
  background: #222;
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .editor__render-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .editor__render-container__cropper {
      overflow: hidden;
      position: relative;
      flex: none;
      cursor: grab;
      background: url(https://static-production.gocase.com.br/transparency.png)
        no-repeat;
      background-size: cover;
      background-position: center;

      .editor__render-container__mask {
        opacity: 0.9;
        position: absolute;
        top: 0;
        left: 0;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        -ms-user-drag: none;
      }
    }
  }
}
</style>
