import { render, staticRenderFns } from "./LiteComponent.vue?vue&type=template&id=01b22152&scoped=true&"
import script from "./LiteComponent.vue?vue&type=script&lang=js&"
export * from "./LiteComponent.vue?vue&type=script&lang=js&"
import style0 from "./LiteComponent.vue?vue&type=style&index=0&id=01b22152&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b22152",
  null
  
)

export default component.exports