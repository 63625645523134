<template>
  <div class="lite-form-component">
    <template v-for="(el, i) in elementsText">
      <text-component
        v-if="showLineForm(el)"
        :key="i + el.identifier"
        :element="el"
        :elements-text="elementsText"
        :language="language"
        @newAiColor="newAiColor"
      />
    </template>
    <container-picker-component
      :elements-image="elementsImage"
      :show-line-form="showLineForm"
      :timestamp="customization.timestamp"
      :language="language"
      :identifier="customization.identifier"
    />
  </div>
</template>

<script>
import ContainerPickerComponent from "@/components/container-picker-component/ContainerPickerComponent.vue";
import TextComponent from "@/components/text-component/TextComponent.vue";

export default {
  components: { ContainerPickerComponent, TextComponent },
  props: {
    customization: Object,
    language: String,
  },
  computed: {
    elementsText() {
      let els = this.customization
        ? this.customization.elements.filter(
            (e) => e.jsClass === "ElementTextModel"
          )
        : null;

      const aiQuestionElsCount = els.filter((el) => {
        const lb = el.label.values.default;
        return !!lb.match("Question AI");
      }).length;

      let elAiPos = 1;
      els = els.map((el) => {
        const lb = el.label.values.default;

        if (!!lb.match("Question AI")) {
          el.showDiscoverAiBtn = elAiPos === aiQuestionElsCount;
          elAiPos += 1;
        } else el.showDiscoverAiBtn = false;

        return el;
      });

      return els;
    },
    elementsImage() {
      return this.customization
        ? this.customization.elements.filter(
            (e) => e.jsClass === "ElementImageModel"
          )
        : null;
    },
  },
  methods: {
    newAiColor(color) {
      this.customization.elements.forEach((el) => (el.color = color));
    },
    showLineForm(element) {
      if (element.label) {
        if (element.jsClass === "ElementImageModel")
          if (!element.components.find((c) => c.canUploadFile))
            if (!element.components.find((c) => c.images.length > 1))
              return false;
            else return true;
          else return true;
        else return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lite-form-component {
  display: grid;
  gap: 16px;
}
</style>
