var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-picker-component"},[_c('h3',{staticClass:"e-picker-component__element-label",attrs:{"data-label":`${_vm.label(_vm.element.label.values)} ${_vm.aditionalLabel}`}}),(_vm.availableComponents.length <= 3)?_vm._l((_vm.availableComponents),function(component){return _c('div',{key:component.identifier,staticClass:"e-picker-component__item"},[(_vm.availableComponents.length > 1)?_c('h4',{staticClass:"e-picker-component__component-label"},[_vm._v(" "+_vm._s(_vm.label(component.label.values))+" ")]):_vm._e(),(component && component.canUploadFile)?_c('image-editor-component',{attrs:{"component":component,"identifier":_vm.identifier}},[(_vm.$store.state.removingBg)?[_c('div',{staticClass:"e-picker-component__file"},[_c('three-dots-loading')],1)]:[_c('div',{staticClass:"e-picker-component__file"},[_c('icon-picture'),_c('span',[_vm._v(_vm._s(_vm.$t("choosePhoto")))])],1)]],2):_vm._e(),(
          component &&
          !(component.canUploadFile && component.images.length === 1)
        )?_c('picker-items-list-component',{attrs:{"show-button":component.images.length > _vm.showedItemsLength,"showed-items-length":_vm.showedItemsLength,"opened":_vm.opened(component)},on:{"click":() => _vm.$emit('change', { component })},scopedSlots:_vm._u([{key:"container",fn:function(){return _vm._l((_vm.componentImages(
              component.images,
              _vm.opened(component)
            )),function(image){return _c('picker-item-component',{key:image.key,attrs:{"image":image,"component":component},on:{"click":_vm.pickItem}})})},proxy:true}],null,true)}):_vm._e()],1)}):[_c('picker-item-multi-component',{attrs:{"available-components":_vm.availableComponents,"showed-items-length":_vm.showedItemsLength,"opened-component":_vm.openedComponent,"label":_vm.label},on:{"label":(v) => (_vm.aditionalLabel = v),"pickItem":_vm.pickItem,"change":(component) => _vm.$emit('change', { component })}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }