<template>
  <div class="e-modal" :class="modalClass">
    <div class="e-modal__backdrop" @click="$emit('close')"></div>
    <div class="e-modal__container" ref="modalContainer">
      <slot name="container" v-if="width" :size="width" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
  data() {
    return {
      width: null,
    };
  },
  computed: {
    modalClass() {
      return this.active ? ["e-modal--open"] : ["e-modal--close"];
    },
  },
  watch: {
    active() {
      setTimeout(
        () => (this.width = this.$refs.modalContainer.offsetWidth),
        300
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.e-modal {
  .e-modal__backdrop {
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba($color: #000, $alpha: 0.7);
    cursor: pointer;
  }

  .e-modal__container {
    background: #fff;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    bottom: 0;
    transition: 0.2s;
  }
}

.e-modal--open {
  .e-modal__container {
    width: 600px;
  }
}

.e-modal--close {
  width: 0;
  height: 0;

  .e-modal__backdrop {
    display: none;
  }

  .e-modal__container {
    width: 0;
  }
}

@media only screen and (max-width: 600px) {
  .e-modal--open {
    .e-modal__container {
      width: 100%;
    }
  }
}
</style>
