<template>
  <input
    class="input-component"
    type="text"
    :ref="`input`"
    :maxlength="element.maxlen"
    :placeholder="element.placeholder"
    v-model="element.value"
    @keyup="change"
    @focusout="handleFocusOut"
    @input="change"
    @focusin="handleFocusIn"
  />
</template>

<script>
export default {
  props: {
    element: Object,
    // disabled: Boolean,
    // error: Boolean,
  },
  data() {
    return {
      debounce: null,
    };
  },
  methods: {
    update() {
      this.$emit("update");
    },
    change() {
      this.element.value = this.element.value.replace(
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
        ""
      );
      if (this.element.value === " ") this.element.value = "";
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => this.update(), 200);
    },
    handleFocusOut() {
      clearTimeout(this.debounce);
      this.update();
    },
    handleFocusIn() {
      this.element.placeholder = "";
      this.element.placeholderPt = "";
      this.element.placeholderEn = "";
      this.update();
    },
  },
  mounted() {
    this.change();
  },
  destroyed() {
    clearTimeout(this.debounce);
    this.update();
  },
};
</script>

<style lang="scss" scoped>
$P800: #ffd8d0;
$P700: #ffc4dd;
$N500: #afafaf;
$E800: #ff4d4d;

.input-component {
  width: 100%;
  color: #333;
  font-family: "Montserrat", helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  outline: none;
  transition: all 0.2s ease-in-out;
  height: 38px;
  padding: 8px 14px;

  box-sizing: border-box;
  border: 1px solid $N500;
  border-radius: 4px;

  &:hover {
    border-color: $P800;
  }

  &:focus {
    border-color: $P800;
    box-shadow: 0 0 5px $P700;
  }

  &--error {
    border-color: $E800;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: $N500;
  }
}
</style>
