<template>
  <div v-if="loading" class="image-editor__loading">
    <bucket-loading />
    <p class="image-editor__loading-msg">{{ texts[i % texts.length] }}</p>
  </div>
</template>

<script>
import BucketLoading from "@gocasebr/go-shared/dist/bucket-loading/BucketLoading";

export default {
  components: { BucketLoading },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      texts: [
        this.$t("loading1"),
        this.$t("loading1"),
        this.$t("loading2"),
        this.$t("loading3"),
        this.$t("loading4"),
      ],
      interval: null,
      i: 0,
    };
  },
  mounted() {
    this.interval = setInterval(() => this.i++, 2000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.image-editor__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba($color: #000000, $alpha: 0.6);
  color: #fff;
  z-index: 11;

  .image-editor__loading-msg {
    font-family: sans-serif;
  }
}
</style>
