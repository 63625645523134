var render = function render(){var _vm=this,_c=_vm._self._c;return (!!_vm.canvas)?_c('div',{staticClass:"editor__render"},[_c('div',{staticClass:"editor__render-container"},[_c('div',{staticClass:"editor__render-container__cropper",style:(_vm.canvasStyle)},[_c('figure',{directives:[{name:"pan",rawName:"v-pan",value:(_vm.onPan),expression:"onPan"}],ref:"imageCurrent",staticStyle:{"margin":"0","position":"absolute","transition":"0.1s"},style:(!!_vm.filter ? _vm.filter.style : {})},[_c('div',{staticStyle:{"width":"100%, height: 100%","position":"relative"}},[_vm._l((!!_vm.filter && !!_vm.filter.overlays
              ? _vm.filter.overlays
              : []),function(overlay,i){return _c('div',{key:i,style:({
              position: `absolute`,
              'mix-blend-mode': overlay.mixBlendMode,
              background: overlay.color,
              opacity: overlay.opacity,
              width: `100%`,
              height: `100%`,
            })})}),_c('img',{ref:"imageElement",attrs:{"crossorigin":"Anonymous","src":_vm.imageCurrent},on:{"load":_vm.adjustmentScale}})],2)]),_c('img',{directives:[{name:"pan",rawName:"v-pan",value:(_vm.onPan),expression:"onPan"}],ref:"imageBefore",staticClass:"editor__render-container__mask",attrs:{"crossorigin":"Anonymous","src":_vm.imageBefore,"width":_vm.imageBeforeWidth,"height":_vm.imageBeforeHeight,"draggable":"false","onmousedown":"return false"}})])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }