import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  de: require("./languages/de.json"),
  en: require("./languages/en.json"),
  es: require("./languages/es.json"),
  fr: require("./languages/fr.json"),
  it: require("./languages/it.json"),
  ptbr: require("./languages/pt-br.json"),
};

const i18n = new VueI18n({
  messages,
  fallbackLocale: "en",
});

export default i18n;
