<template>
  <div class="e-lite" v-if="$settings.admin">
    <div id="e-preview" class="t-device"></div>
    <div class="e-form">
      <div id="e-inputs"></div>
      <div id="e-export"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import CanvasComponent from "@/components/canvas-component/CanvasComponent.vue";
import ExportComponent from "@/components/export-component/ExportComponent.vue";
import LiteFormComponent from "@/components/lite-form-component/LiteFormComponent.vue";

import PreviewComponent from "@gocasebr/go-shared/dist/preview-component/PreviewComponent";
import GalleryComponent from "@gocasebr/go-shared/dist/gallery-component/GalleryComponent";

import { getMockup } from "@/api/catalog-api/mockup-api/mockup.api.js";
import { generate3dImage } from "@/api/prisma-render-api/generate-3d-image-api/generate3dImage.api.js";
import { getMaterialFromFactory } from "@/api/factory-api/material-api/material.api.js";

let inputsMount = null;
let exportMount = null;
let previewMount = null;

export default {
  props: {
    customization: Object,
    settings: Object,
  },
  watch: {
    customization() {
      this.init();
    },
  },
  data() {
    return {
      newStamp: null,
    };
  },
  computed: {
    Vue() {
      return Vue;
    },
    admin() {
      return this.settings.admin;
    },
    showTextBorder() {
      if (!this.settings.devMode) return;
      return this.settings.devMode.showTextBorder;
    },
    inputsMountId() {
      if (!this.settings.liteMode) return;
      return this.settings.liteMode.inputsMountId;
    },
    exportMountId() {
      if (!this.settings.liteMode) return;
      return this.settings.liteMode.exportMountId;
    },
    previewMountId() {
      if (!this.settings.liteMode) return;
      return this.settings.liteMode.previewMountId;
    },
    previewHeight() {
      if (!this.settings.liteMode) return;
      return this.settings.liteMode.previewHeight;
    },
    language() {
      if (!this.settings.language) return "en";
      return this.settings.language;
    },
    btnText() {
      const { exportBtn, viewData } = this.settings;
      if (exportBtn && exportBtn.btnText) return exportBtn.btnText;
      if (viewData && viewData.btnExportName) return viewData.btnExportName;
      return this.$t("addToCart");
    },
    btnDisabledFunc() {
      if (!this.settings.exportBtn) return;
      return this.settings.exportBtn.btnDisabledFunc;
    },
    eventExportImage() {
      if (!this.settings.events) return;
      return this.settings.events.exportImage;
    },
  },
  methods: {
    destroy(instance) {
      if (!instance) return instance;
      instance.$children.forEach((el) => el.$destroy());
      return;
    },
    async init() {
      previewMount = this.destroy(previewMount);
      inputsMount = this.destroy(inputsMount);
      exportMount = this.destroy(exportMount);

      // TODO: Enquanto tem 2 mockups
      const {
        customization,
        language,
        btnText,
        btnDisabledFunc,
        eventExportImage,
      } = this;

      let material = null;
      let component = null;

      await Promise.all([
        getMockup(
          customization.material,
          customization.store,
          customization.mockups
        ).then((res) => {
          material = res;
          component = !!material.jsClass ? GalleryComponent : PreviewComponent;
        }),
        getMaterialFromFactory(customization.material).then((res) => {
          customization.logo = {
            x: res.logo_x,
            y: res.logo_y,
            color: res.logo_color,
            applies_color: res?.name?.includes("leather"),
            border_color: res.logo_border_color,
            applies_border_color: res.applies_logo_border_color,
          };

          customization.canvasContext = {
            width: res.mask_data.width,
            height: res.mask_data.height,
          };
        }),
      ]);

      const stamp = this.language.includes("pt")
        ? customization.previewPt
        : customization.previewEn;

      if (!previewMount) {
        previewMount = this.mount({
          content: this.previewMountId,
          id: "engine-device-preview-lite-unique",
          component,
          settings: {
            on: {
              canvas: (e) => {
                if (!inputsMount)
                  inputsMount = this.mount({
                    content: this.inputsMountId,
                    id: "engine-inputs-lite-unique",
                    component: LiteFormComponent,
                    settings: {
                      props: { language, customization },
                    },
                  });
                if (!exportMount)
                  exportMount = this.mount({
                    content: this.exportMountId,
                    id: "engine-export-lite-unique",
                    component: ExportComponent,
                    settings: {
                      props: {
                        language,
                        customization,
                        canvasEvent: e,
                        btnText,
                        btnDisabledFunc,
                        eventExportImage,
                      },
                    },
                  });
              },
            },
            props: {
              language: this.language,
              canvasComponent: CanvasComponent,
              galleryProps: {
                material: material.material,
                height: this.previewHeight,
                colorDeviceMountId: this.settings.liteMode.colorDeviceMountId,
                thumbnailsMountId: this.settings.liteMode.thumbnailsMountId,
                Vue: this.Vue,
                i18n: this.$i18n,
                timestamp: customization.timestamp,
                deviceColorTitle: this.$t("deviceColor"),
                is3d:
                  material &&
                  material.material &&
                  material.material.match("360") &&
                  !material.material.includes("copolife1180") &&
                  !material.material.includes("freshmini") &&
                  !material.material.includes("garrafamini"),
                stamp,
              },
              previewProps: {
                material: material.material,
                height: this.previewHeight,
                deviceProportionInScreen: 0.85,
                customization,
                timestamp: customization.timestamp,
              },
              canvas: {
                admin: this.admin,
                showTextBorder: this.showTextBorder,
                customization,
              },
            },
          },
        });
      }
    },
    mount({ content, id, component, settings }) {
      if (!document.getElementById(content)) return;
      document.getElementById(content).innerHTML = `<div id="${id}"></div>`;
      if (document.getElementById(id))
        return new this.Vue({
          i18n: this.$i18n,
          store: this.$store,
          render: (h) => h(component, settings),
        }).$mount(`#${id}`);
    },
  },
  mounted() {
    window.addEventListener("generate3dImage", async () => {
      const customization = this.customization;

      const imageUrl = await generate3dImage({
        customization,
        return_base64: true,
      });

      window.dispatchEvent(
        new CustomEvent("new3dStamp", {
          detail: {
            img: imageUrl.base64,
          },
        })
      );
    });
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.e-lite {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  gap: 12px;

  .t-device {
    position: relative;
    margin: 0 auto;
  }

  .e-form {
    position: relative;
  }
}
</style>
