/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");

const getSimilarGroup = () =>
  httpClient
    .get(`/public/similar_groups`, {
      params: { per_page: 100 },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const setSimilarGroup = (group) =>
  httpClient
    .post(`/similar_group`, { group }, { "Content-Type": "application/json" })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const destroySimilarGroup = ({ identifier }) =>
  httpClient
    .delete(`/similar_group/${identifier}`, {
      headers: {
        Authorization: "***",
      },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const updateStampSimilarGroup = ({ stamp, id }) => {
  const formData = new FormData();
  formData.append("stamp", stamp);
  formData.append("id", id);
  return httpClient
    .post(`/similar_group/update_stamp`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export {
  getSimilarGroup,
  setSimilarGroup,
  destroySimilarGroup,
  updateStampSimilarGroup,
};
