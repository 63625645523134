/* istanbul ignore file */
import Vue from "vue";
import { Notifier } from "@airbrake/browser";

let airbrake;
const { version } = require("../../package.json");

if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "development"
) {
  airbrake = new Notifier({
    projectId: process.env.VUE_APP_AIRBRAKE_PROJECT_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_PROJECT_API_KEY,
    environment: process.env.NODE_ENV,
  });

  airbrake.addFilter(function (notice) {
    if (notice.params.engineId !== process.env.VUE_APP_ENGINE_ID) {
      // Ignore errors from outside Vue app.
      return null;
    }
    return notice;
  });

  function notifyError(err, vm, info) {
    airbrake.notify({
      error: err,
      params: {
        info: info,
        engineId: process.env.VUE_APP_ENGINE_ID,
        appVersion: version,
      },
    });
  }

  Vue.prototype.$airbrake = airbrake;
  Vue.prototype.$notifyError = notifyError;

  Vue.prototype.$forceError = () => {
    throw new Error("Fake error!");
  };

  if (process.env.NODE_ENV === "production") {
    Vue.config.errorHandler = notifyError;
  }
}

export default airbrake;
