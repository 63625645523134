<template>
  <div class="element-image" v-if="!!width && !!height">
    <component-image
      v-for="(component, i) in element.components"
      :key="i"
      :element="element"
      :image="image(component)"
      :component="component"
      :width="width"
      :height="height"
      :preview-context="previewContext"
    />
  </div>
</template>

<script>
import ComponentImage from "./component-image/ComponentImage.vue";

export default {
  components: { ComponentImage },
  props: {
    element: Object,
    previewContext: Object,
  },
  data() {
    return {
      width: null,
      height: null,
      useImageKit: false,
    };
  },
  methods: {
    image(component) {
      const activeImage = component.images.find((im) => im.active && im.show);
      if (!activeImage) return;

      activeImage.src = this.imageSrc(activeImage.src);
      return activeImage;
    },
    imageSrc(src) {
      let localSrc = src.replace("http://", "https://");

      const { cloudflareHost, imageKitHost } = this.$settings;
      return this.useImageKit
        ? localSrc.replace(cloudflareHost, imageKitHost)
        : localSrc;
    },
    initComponent() {
      const img = new Image();

      img.onload = () => ((this.width = img.width), (this.height = img.height));
      img.onerror = () => {
        this.useImageKit = true;
        this.initComponent();
      };

      const src = this.element.components
        .find((c) => !!c)
        .images.find((i) => !!i).src;
      img.src = this.imageSrc(src);
    },
  },
  mounted() {
    this.initComponent();
  },
};
</script>

<style lang="scss" scoped>
.element-image {
  /* position: absolute; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
