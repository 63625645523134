var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"patternMode",class:[
    'pattern-mode',
    {
      'pattern-mode--1': _vm.columns === 1,
      'pattern-mode--2': _vm.columns === 2,
      'pattern-mode--3': _vm.columns === 3,
      'pattern-mode--4': _vm.columns === 4,
      'pattern-mode--5': _vm.columns === 5,
      'pattern-mode--6': _vm.columns === 6,
    },
  ],style:({ width: '100%', height: '100%' })},_vm._l((_vm.computedEmojis),function(emoji,index){return _c('div',{key:index,staticClass:"pattern-mode__wrapper",style:({ width: _vm.bigSize + 'px', height: _vm.bigSize + 'px' })},[_c('img',{staticClass:"pattern-mode__image",style:({
        width: emoji.size + 'px',
        height: emoji.size + 'px',
        transform: `rotate(${emoji.rotation}deg)`,
      }),attrs:{"src":emoji.src,"alt":"emoji"}})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }