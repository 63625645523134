/* istanbul ignore file */
import axios from "axios";

const baseURL = "";

const httpClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    // anything you want to add to the headers
  },
});

export { httpClient };
