/* istanbul ignore file */
const FACTORY = require("./factory-api/factory.api.js");
const PRISMA_RENDER = require("./prisma-render-api/prismaRender.api.js");
const CATALOG = require("./catalog-api/catalog.api.js");
const METABASE = require("./metabase-api/metabase.api.js");

export default class Api {
  static FACTORY_API = "https://factory.gocase.com.br/api/v1";
  static PRISMA_RENDER_API = "https://prisma.gocase.com.br";
  static CATALOG_API = "https://go-catalog-api-staging.herokuapp.com/api/v1";
  static METABASE_API =
    "https://cors-anywhere.herokuapp.com/http://metabase.gocase.com.br/api/public";

  static update({ FACTORY_API, PRISMA_RENDER_API, CATALOG_API, METABASE_API }) {
    FACTORY.httpClient.defaults.baseURL = FACTORY_API
      ? FACTORY_API
      : this.FACTORY_API;
    PRISMA_RENDER.httpClient.defaults.baseURL = PRISMA_RENDER_API
      ? PRISMA_RENDER_API
      : this.PRISMA_RENDER_API;
    CATALOG.httpClient.defaults.baseURL = CATALOG_API
      ? CATALOG_API
      : this.CATALOG_API;
    METABASE.httpClient.defaults.baseURL = METABASE_API
      ? METABASE_API
      : this.METABASE_API;
  }

  static setCatalogToken({ token }) {
    CATALOG.httpClient.defaults.headers.Authorization = token;
  }
}
