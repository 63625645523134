export default {
  methods: {
    goToLabel() {
      // THIS LOGIC IS SPECIFIC TO V4 WEBSITE
      const imageContainer = document.getElementById("main-image") || {
        clientHeight: 414,
      };

      window.scroll({
        top:
          window.scrollY +
          this.$el.getBoundingClientRect().top -
          (window.innerWidth < 1024
            ? imageContainer.clientHeight
            : imageContainer.clientHeight / 2) -
          20,
        left: 0,
        behavior: "smooth",
      });
    },
    showLess() {
      this.goToLabel();
    },
    showMore() {
      if (
        ["https://www.gocase.com.br", "https://www.gocase.com"].includes(
          window.location.origin
        ) ||
        window.location.origin.match("localhost")
      ) {
        setTimeout(() => {
          this.goToLabel();
        }, 100);
      }
    },
  },
};
