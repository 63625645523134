/* istanbul ignore file */
const { httpClient } = require("../factory.api.js");

const getMaterials = () =>
  httpClient
    .get(`/material_categories/materials?material_category_sku=case`)
    .then((response) => response.data.materials)
    .catch((error) => Promise.reject(error));

const getMaterialFromFactory = (code) =>
  httpClient
    .get(`/customizr_formatted_structure?material_slug=${code}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export { getMaterials, getMaterialFromFactory };
