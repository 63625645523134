<template>
  <div class="image-editor">
    <label class="image-editor__btn" :for="component.identifier + component.id">
      <slot />
    </label>
    <input
      type="file"
      ref="inputImageCurrent"
      @change="setCurrentImage"
      :id="component.identifier + component.id"
      accept=".png, .jpg, .jpeg"
      v-show="false"
    />

    <!-- nao abrir modal ir direto para mostrar na tela -->

    <right-modal-component :active="active" @close="active = false">
      <template v-slot:container="{ size }">
        <div v-if="available" class="image-editor__modal-body">
          <div class="image-editor__modal-body__header">
            <p class="image-editor__modal-body__title">
              {{ $t("photoEditor") }}
            </p>
            <div
              class="image-editor__modal-body__close"
              @click="active = false"
            >
              <icon-cross />
            </div>
          </div>

          <div class="image-editor__modal-body__main">
            <editor-component
              v-if="imageCurrent"
              :size-square="size"
              :image-before-width="width"
              :image-before-height="height"
              :image-before-is-mask="componentImageMasked"
              :image-before-padding="componentImagePadding"
              :image-before="imageBefore"
              :image-current="imageCurrent"
              :filter="filter"
              :set-flip="setFlip"
              :set-scale="setScale"
              :set-rotation="setRotation"
              @cropper="(f) => (cropperFunc = f)"
              @imageCurrentLoaded="imageCurrentLoaded = true"
            />
          </div>

          <div class="image-editor__modal-body__footer">
            <filters-component :image="imageCurrent" @filter="setFilter" />
            <options-component
              @flip="(v) => (setFlip = v)"
              @scale="(v) => (setScale = v)"
              @rotate="(v) => (setRotation = v)"
              @done="cropperFunc(getImageCallback)"
            />
          </div>
        </div>

        <editor-loading-component :loading="loading" />
      </template>
    </right-modal-component>
  </div>
</template>

<script>
import IconCross from "@gocasebr/go-shared/dist/cross/Cross";
import RightModalComponent from "@/components/right-modal-component/RightModalComponent.vue";
import EditorComponent from "./editor-component/EditorComponent.vue";
import OptionsComponent from "./options-component/OptionsComponent.vue";
import FiltersComponent from "./filters-component/FiltersComponent.vue";
import EditorLoadingComponent from "./editor-loading-component/EditorLoadingComponent.vue";
import removeImageBg from "@/utils/removeImageBg.js";
import headExtract from "@/utils/headExtract.js";
import petHeadExtract from "@/utils/petHeadExtract.js";

import { uploadImage } from "@/api/catalog-api/generic-image-api/genericImage.api.js";

export default {
  components: {
    IconCross,
    RightModalComponent,
    EditorComponent,
    OptionsComponent,
    FiltersComponent,
    EditorLoadingComponent,
  },
  props: { component: Object, timestamp: [Number, String], identifier: String },
  data() {
    return {
      active: false,
      loading: false,
      width: null,
      height: null,
      imageBefore: null,
      imageCurrent: null,
      cropperFunc: null,
      filter: null,
      setScale: null,
      setFlip: null,
      setRotation: null,
      imagePath: null,
    };
  },
  computed: {
    componentImageActive() {
      return this.component.images.find((el) => el.active);
    },
    componentImageActiveSrc() {
      const img =
        this.componentImageActive.temp || this.componentImageActive.src;
      return `${img}?t=000`;
    },
    componentImageMasked() {
      return this.component.pictureMasked;
    },
    componentImagePadding() {
      return {
        top: this.component.picturePaddingTop,
        left: this.component.picturePaddingLeft,
        bottom: this.component.picturePaddingBottom,
        right: this.component.picturePaddingRight,
      };
    },
    available() {
      return (
        !!this.active &&
        !!this.imageBefore &&
        !!this.width &&
        !!this.height &&
        !!this.componentImagePadding
      );
    },
  },
  watch: {
    active() {
      this.init();
      this.rotation = 0;
      this.imageCurrentLoaded = false;
    },
  },
  methods: {
    update() {
      this.$emit("update");
    },
    setFilter(f) {
      this.filter = f;
    },
    async setCurrentImage(f) {
      const target = f?.target;
      const file = target?.files[0];
      const _URL = window.URL || window.webkitURL;

      this.$store.dispatch("removingBg", true);

      if (!!file) {
        if (
          this.component.remove_bg ||
          (this.$store && this.$store.state.enabledControls)
        ) {
          const resultimg = await this.removeImgBg(file);
          this.imageCurrent =
            typeof resultimg !== "object"
              ? resultimg
              : _URL.createObjectURL(resultimg);
        } else {
          this.imageCurrent = _URL.createObjectURL(file);
        }
        this.active = true;
        this.imagePath = target.value;
      } else target.value = "";

      this.$store.dispatch("removingBg", false);
    },
    async removeImgBg(imageFile) {
      console.log(`removing bg (${imageFile.type})`);
      try {
        return await removeImageBg(imageFile, "gocase", this.identifier);
      } catch (error) {
        // TODO: alert user that the image bg could not be removed
        console.log(`error removing bg ${error}`, "error");
        return imageFile;
      }
    },
    saveImageInputs() {
      const data = {
        inputId: this.component.identifier + this.component.id,
        imagePath: this.imagePath,
      };
      let imageInputs = JSON.parse(localStorage.getItem("imageInputs")) || [];
      const existingInput = imageInputs.find(
        (el) => el.inputId === data.inputId
      );
      if (existingInput) {
        existingInput.imagePath = data.imagePath;
      } else {
        imageInputs.push(data);
      }
      localStorage.setItem("imageInputs", JSON.stringify(imageInputs));
    },
    async getImageCallback(image) {
      this.loading = true;

      if (this.$store.state.patternModeActive) {
        async function convertUrlToFile(url, fileName) {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Falha ao obter a imagem.");
          }

          const blob = await response.blob();

          return new File([blob], fileName, { type: blob.type });
        }

        async function convertBlobToFile(blob, fileName) {
          return new File([blob], fileName, { type: blob.type });
        }
        if (this.$store.state.patternEngineName === "pet-face-extraction") {
          image = await convertBlobToFile(await petHeadExtract(image));
        } else {
          image = await convertUrlToFile(await headExtract(image));
        }
      }

      uploadImage({ image })
        .then((res) => {
          const i = new window.Image();
          i.crossOrigin = "Anonymous";
          i.onload = () => {
            this.componentImageActive.temp = this.componentImageActiveSrc;
            this.componentImageActive.src = res.src;
            this.loading = false;
            this.active = false;
            this.update();
            this.saveImageInputs();
          };
          i.src = `${res.src}?t=${this.timestamp}`;
        })
        .catch(() => {
          this.loading = false;
          this.active = false;
          alert(this.$t("errorUploadImage"));
        });
    },
    init() {
      const image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = () => {
        this.width = image.width;
        this.height = image.height;
        this.imageBefore = this.componentImageActiveSrc;

        if (this.componentImageMasked) {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0);
          ctx.globalCompositeOperation = "source-out";
          ctx.beginPath();
          ctx.rect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = "white";
          ctx.fill();
          this.imageBefore = canvas.toDataURL("image/png", 0.1);
        }
      };
      image.src = this.componentImageActiveSrc;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.image-editor {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.image-editor__btn {
  width: 100%;
}

.image-editor__modal-body {
  width: 100%;
  height: 100%;
  background: #222;
  position: relative;
  display: flex;
  flex-direction: column;

  .image-editor__modal-body__header {
    height: 56px;
    position: relative;
    border-bottom: 1px solid #f2f2f6;
    padding-left: 15px;
    display: flex;
    align-items: center;
    background: #fff;

    .image-editor__modal-body__title {
      font-family: sans-serif;
      text-transform: uppercase;
      text-align: left;
      font-size: 16px;
      line-height: 56px;
      margin: 0;
      flex: 1;
    }

    .image-editor__modal-body__close {
      width: 20px;
      height: 20px;
      padding: 0 15px;
      cursor: pointer;
    }
  }

  .image-editor__modal-body__main {
    height: calc(100% - 56px - 170px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .image-editor__modal-body__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
</style>
