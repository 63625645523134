import axios from "axios";

const REMOVE_BG_API_URL =
  "https://www.ailabapi.com/api/cutout/portrait/avatar-extraction";
const REMOVE_BG_API_KEY =
  "dTdsMUDXxZrgKkYIQwzT2MF6KG52Dk945C74efHlyuEjN3hZLnAEYtql0mcvCbst";

const log = (...args) => console.log(`[img-bg]`, ...args);

async function headExtract(image_file) {
  const formData = new FormData();
  formData.append("image", image_file);
  // formData.append("format", "png");

  const response = await axios.post(REMOVE_BG_API_URL, formData, {
    headers: {
      "ailabapi-api-key": REMOVE_BG_API_KEY,
    },
  });

  log(`Response status:`, response.status);

  const imgBlob = await response.data;

  console.log(imgBlob, "blob");

  return imgBlob.data.elements[0].image_url;
}

export function previewBlobImg(blob) {
  const objectURL = URL.createObjectURL(blob);
  window.open(objectURL);
}

export default headExtract;
