<template>
  <svg viewBox="0 0 23 20">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.0890007 9.59601C0.138 9.46601 0.212 9.34301 0.316999 9.23801L8.269 1.28501C8.668 0.886009 9.316 0.886009 9.715 1.28501C10.114 1.68501 10.114 2.33201 9.715 2.73101L3.446 9.00001L22 9.00001C22.552 9.00001 23 9.44801 23 10C23 10.552 22.552 11 22 11L3.445 11L9.715 17.27C10.114 17.669 10.114 18.317 9.715 18.716C9.316 19.115 8.668 19.115 8.269 18.716L0.316999 10.764C0.212 10.658 0.137999 10.535 0.0879993 10.404C0.032999 10.28 0 10.144 0 10C0 9.85601 0.0330009 9.72001 0.0890007 9.59601Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
