/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");

const uploadImage = ({ image }) => {
  const formData = new FormData();
  formData.append("image", image);
  return httpClient
    .post(`/public/generic_image`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export { uploadImage };
