/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");

//CATALOGV1
// const createLineItemDesignCustomization = ({
//   image_name,
//   material,
//   identifier,
//   image_src,
//   engine_info,
// }) =>
//   httpClient
//     .post(`/line_item_design_customization`, {
//       image_name,
//       material,
//       identifier,
//       image_src,
//       engine_info,
//     })
//     .then((response) => response.data)
//     .catch((error) => Promise.reject(error));

//CATALOGV2
const createLineItemDesignCustomization = ({
  image_name,
  material,
  identifier,
  image_src,
  engine_info,
}) =>
  httpClient
    .post(`/public/line_item_design_customizations`, {
      image_name,
      material,
      identifier,
      image_src,
      engine_info,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

//CATALOGV2
const getLineItemHash = ({ image_src }) =>
  httpClient
    .post(`/public/line_item_hash`, { image_src })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export { createLineItemDesignCustomization, getLineItemHash };
