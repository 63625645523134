<template>
  <div class="container-picker-component">
    <template v-for="(el, i) in elementsImage">
      <picker-component
        v-if="showLineForm(el)"
        :element="el"
        :timestamp="timestamp"
        :key="i + el.identifier"
        :language="language"
        :opened-component="openedComponent"
        @change="handleChange"
        :identifier="identifier"
        :elements="elementsImage"
        v-show="!el.identifier.includes('rapport')"
      />
    </template>
  </div>
</template>

<script>
import PickerComponent from "@/components/picker-component/PickerComponent.vue";

export default {
  components: { PickerComponent },
  props: {
    elementsImage: Array,
    showLineForm: Function,
    language: String,
    timestamp: [String, Number],
    identifier: String,
  },
  data() {
    return {
      openedComponent: null,
    };
  },
  computed: {},
  methods: {
    handleChange({ component }) {
      this.openedComponent =
        this.openedComponent === component ? null : component;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-picker-component {
  display: grid;
  gap: 16px;
}
</style>
