<template>
  <div
    ref="patternMode"
    :class="[
      'pattern-mode',
      {
        'pattern-mode--1': columns === 1,
        'pattern-mode--2': columns === 2,
        'pattern-mode--3': columns === 3,
        'pattern-mode--4': columns === 4,
        'pattern-mode--5': columns === 5,
        'pattern-mode--6': columns === 6,
      },
    ]"
    :style="{ width: '100%', height: '100%' }"
  >
    <div
      v-for="(emoji, index) in computedEmojis"
      :key="index"
      class="pattern-mode__wrapper"
      :style="{ width: bigSize + 'px', height: bigSize + 'px' }"
    >
      <img
        :src="emoji.src"
        :style="{
          width: emoji.size + 'px',
          height: emoji.size + 'px',
          transform: `rotate(${emoji.rotation}deg)`,
        }"
        alt="emoji"
        class="pattern-mode__image"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: Number,
      default: 1,
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      computedEmojis: [],
      bigSize: 0,
      smallSize: 0,
    };
  },
  computed: {
    images() {
      // return [
      //   "https://static-goengines.gocase.com.br/uploads/image/171670/thumb/d5eed07f4ca6e7c2792a5a29bcb423b5.png", // Substitua pelos caminhos corretos das imagens
      //   "https://static-goengines.gocase.com.br/uploads/image/171651/thumb/e55b132068d8f78fb0247df48c92abe5.png",
      //   "https://static-goengines.gocase.com.br/uploads/image/171811/thumb/485c13c0ba1171546f19738f5c4a6cbe.png",
      // ];
      return this.elements.map((el) => {
        const img = el.components
          .find((com) => com.images.find((im) => !!im.active))
          .images.find((im) => !!im.active);

        return {
          src: img.src,
          rotation: el.rotation,
        };
      });
    },
  },
  watch: {
    images(_) {
      this.calculateLayout();
    },
  },
  mounted() {
    this.calculateLayout();
    window.addEventListener("resize", this.calculateLayout);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateLayout);
  },
  methods: {
    calculateLayout() {
      const container = this.$refs.patternMode;
      const containerWidth = container.offsetWidth;

      this.bigSize = (containerWidth - (this.columns - 1) * 5) / this.columns;

      if (this.mode === 1) {
        this.smallSize = this.bigSize * 1;
      } else {
        this.smallSize = this.bigSize * 0.85;
      }

      const rows = Math.floor(
        (container.offsetHeight - this.columns) / (this.bigSize + this.columns)
      );
      const totalEmojis = rows * this.columns;

      this.computedEmojis = [];
      for (let i = 0; i < totalEmojis; i++) {
        this.computedEmojis.push({
          src: this.images[i % this.images.length].src,
          size: i % 2 === 0 ? this.bigSize : this.smallSize,
          rotation: this.images[i % this.images.length].rotation,
        });
      }
    },
  },
};
</script>

<style scoped>
.pattern-mode {
  display: grid;
}
.pattern-mode--1 {
  grid-template-columns: auto;
}
.pattern-mode--2 {
  grid-template-columns: auto auto;
}
.pattern-mode--3 {
  grid-template-columns: auto auto auto;
}
.pattern-mode--4 {
  grid-template-columns: auto auto auto auto;
}
.pattern-mode--5 {
  grid-template-columns: auto auto auto auto auto;
}
.pattern-mode--6 {
  grid-template-columns: auto auto auto auto auto auto;
}

.pattern-mode__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.pattern-mode__wrapper img {
  object-fit: contain;
}
</style>
