export default {
  state: {
    alerts: [],
  },
  mutations: {
    addAlert(state, alert) {
      state.alerts.push(alert);
    },
    removeAlert(state, alert) {
      state.alerts = state.alerts.filter((v) => v !== alert);
    },
  },
  actions: {
    addAlert({ commit }, alert) {
      commit("addAlert", alert);
    },
    removeAlert({ commit }, alert) {
      commit("removeAlert", alert);
    },
  },
  getters: {
    alertsOk: (state) => {
      return state.alerts.length === 0;
    },
  },
};
