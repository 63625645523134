export default {
  methods: {
    enableControls() {
      this.enableListenersControls();
      this.disableGoSharedControlsProtections();
      this.controlsUsability();
    },
    controlsUsability() {
      document.addEventListener("click", (e) => {
        if (
          !e.target.closest(".component-text") &&
          !e.target.closest(".component-image") &&
          !e.target.closest(".prisma-controls")
        ) {
          this.$store.dispatch("setCurrentElement", {
            element: null,
          });
        }
      });
    },
    disableGoSharedControlsProtections() {
      document.querySelectorAll("div").forEach((div) => {
        if (div) div.style["pointer-events"] = "auto";
      });

      document
        .querySelectorAll(".mockup-preview-dynamic-render__effect")
        .forEach((el) => el.remove());

      const mocklist = document.querySelector(".mockup-list");
      if (mocklist) mocklist.style.cursor = "auto";
    },
    enableListenersControls() {
      this.enableEventListenerTop();
      this.enableEventListenerBottom();
      this.enableEventListenerLeft();
      this.enableEventListenerRight();
      this.enableEventListenerRotateLeft();
      this.enableEventListenerRotateRight();
      this.enableEventListenerZoomPlus();
      this.enableEventListenerZoomLess();
    },
    enableEventListenerTop() {
      this.enableEventListener("top", (el) => (el.my = el.my - 0.005));
    },
    enableEventListenerBottom() {
      this.enableEventListener("bottom", (el) => (el.my = el.my + 0.005));
    },
    enableEventListenerLeft() {
      this.enableEventListener("left", (el) => (el.mx = el.mx - 0.005));
    },
    enableEventListenerRight() {
      this.enableEventListener("right", (el) => (el.mx = el.mx + 0.005));
    },
    enableEventListenerRotateLeft() {
      this.enableEventListener(
        "rotate-left",
        (el) => (el.rotation = el.rotation - 1)
      );
    },
    enableEventListenerRotateRight() {
      this.enableEventListener(
        "rotate-right",
        (el) => (el.rotation = el.rotation + 1)
      );
    },
    enableEventListenerZoomPlus() {
      this.enableEventListener("zoom-plus", (el) => {
        el.scaleX = el.scaleX + 0.005;
        el.scaleY = el.scaleY + 0.005;
      });
    },
    enableEventListenerZoomLess() {
      this.enableEventListener("zoom-less", (el) => {
        el.scaleX = el.scaleX - 0.005;
        el.scaleY = el.scaleY - 0.005;
      });
    },
    enableEventListener(controlCode, cb) {
      window.addEventListener(
        `prisma:control:${controlCode}`,
        ({ detail: { componentId } }) => {
          this.customization.elements.forEach((el) => {
            if (el.id === componentId) {
              cb(el);
            }
          });
        }
      );
    },
  },
};
