<template>
  <button
    type="button"
    class="export-component"
    :class="{ 'export-component--disabled': disabled || isLoad }"
    @click="save()"
  >
    <span class="export-component__text" v-if="!isLoad">
      <icon-cart class="export-component__icon" />
      {{ btnText }}
    </span>
    <spinner v-else />
  </button>
</template>

<script>
import IconCart from "@gocasebr/go-shared/dist/cart/Cart";
import Spinner from "@gocasebr/go-shared/dist/spinner/Spinner";

import { createLineItemDesignCustomization } from "@/api/catalog-api/line-item-design-customization-api/lineItemDesignCustomization.api.js";

export default {
  name: "ExportComponent",
  components: { IconCart, Spinner },
  data() {
    return { isLoad: false };
  },
  props: {
    canvasEvent: Object,
    btnText: String,
    btnDisabledFunc: Function,
    eventExportImage: String,
  },
  computed: {
    beforeExport() {
      const { canvasEvent } = this;
      if (canvasEvent.name !== "beforeExport") return;
      return canvasEvent.value;
    },
    disabled() {
      return !this.$store.getters.alertsOk;
    },
  },
  methods: {
    // validate() {
    //   let valid = true;

    //   this.elementsTextModel.forEach((element) => {
    //     if (!element.value && !element.disabled) {
    //       valid = false;
    //       this.$store.dispatch("addElementError", {
    //         element,
    //         error: "emptyInput",
    //       });
    //     }
    //   });

    //   if (!valid) return;
    // },
    save() {
      if (this.beforeExport && !this.disabled) {
        this.beforeExport((data) => {
          if (data) {
            if (this.$store.state.patternModeActive) {
              const els = data.customization.elements;

              data.customization.elements = [];

              const patternContainer = document.querySelector(".pattern-mode");
              const patternContainerWidth = patternContainer.offsetWidth;
              const patternContainerHeight = patternContainer.offsetHeight;

              els.forEach((el) => {
                const activeSrc = el.components[0].images.find(
                  (img) => img.active
                ).src;

                const docEls = Array.from(
                  document.querySelectorAll(`[src="${activeSrc}"]`)
                );

                docEls.forEach((docEL) => {
                  el = JSON.parse(JSON.stringify(el));
                  const docWrapper = docEL;

                  if (docWrapper) {
                    const posX = docWrapper.offsetLeft;
                    const posY = docWrapper.offsetTop;
                    let parentWidth = docWrapper.offsetWidth;
                    let parentHeight = docWrapper.offsetHeight;

                    if (
                      data.customization.identifier.match("face-extraction")
                    ) {
                      parentWidth = parentWidth - parentWidth * 0.3;
                      parentHeight = parentHeight - parentHeight * 0.3;
                    }

                    el.width = parentWidth;
                    el.height = parentHeight;
                    el.alignX = "left";
                    el.alignY = "top";
                    el.scaleX = parentWidth / patternContainerWidth;
                    el.scaleY = el.scaleX;

                    let normalizedPosX = (posX / parentWidth) * el.scaleX;
                    let normalizedPosY =
                      (posY / parentHeight) *
                      (parentHeight / patternContainerHeight);

                    // Certifica-se de que a porcentagem não exceda 100
                    normalizedPosX = Math.min(Math.max(normalizedPosX, 0), 100);
                    normalizedPosY = Math.min(Math.max(normalizedPosY, 0), 100);

                    el.mx = normalizedPosX;
                    el.my = normalizedPosY;
                    data.customization.elements.push(el);
                  } else {
                    console.log("El ou container pai não encontrado.");
                  }
                });
              });
            }

            this.isLoad = true;
            this.saveHashApi(data)
              .then((res) => {
                this.emitEvent(res.image_src);
                this.isLoad = false;
                return res;
              })
              .catch(() =>
                this.notify("Failed SaveAPI", this, data.customization)
              );
          }
        });
      } else this.btnDisabledFunc();
    },
    emitEvent(img) {
      dispatchEvent(
        new CustomEvent(this.eventExportImage, {
          detail: { img },
        })
      );
    },
    notify(err, vm, info) {
      this.isLoad = false;
      alert(
        `Desculpa, não conseguimos finalizar seu pedido :( \nPor favor tente novamente ou entre em contato com o nosso suporte!`
      );
      this.$notifyError(err, vm, info);
    },
    resourceParams(customization) {
      // eslint-disable-next-line
      const hexDigits = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
      ];
      const rgb2hex = (rgb) => {
        if (rgb.includes("rgb(")) {
          rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
          return hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
        } else return rgb.split("#")[1];
      };
      const hex = (x) =>
        isNaN(x) ? "00" : hexDigits[(x - (x % 16)) / 16] + hexDigits[x % 16];

      const params = [];
      customization.elements.forEach((el) => {
        if (el.parameterCode) {
          if (el.jsClass === "ElementTextModel") {
            params.push({
              key: `etm-${el.parameterCode}-${rgb2hex(el.color)}`,
              value: el.value,
            });
          } else if (el.jsClass === "ElementImageModel") {
            el.components.forEach((co) => {
              if (co.parameterCode) {
                const img = co.images.find((im) => im.active && im.show);
                if (img && img.parameterCode) {
                  params.push({
                    key: `eim-${el.parameterCode}-${co.parameterCode}`,
                    value: img.parameterCode,
                  });
                }
              }
            });
          }
        }
      });

      return params;
    },
    saveHashApi({ customization }) {
      customization.preview = null;
      customization = this.fitCustomization(customization);
      const image_name = `${
        Math.random().toString().split(".")[1] +
        Date.now().toString() +
        Math.random().toString().split(".")[1]
      }.png`;
      const hash = {
        image_name,
        material: customization.material,
        identifier: customization.identifier,
        engine_info: JSON.stringify({
          name: `engine/${customization.identifier}/${customization.material}/${image_name}`,
          customization,
          material: customization.material,
          version: Date.now().toString(),
        }),
      };
      return createLineItemDesignCustomization(hash);
    },
    fitCustomization(customization) {
      customization.elements = customization.elements
        .map((el) => {
          if (el.jsClass === "ElementImageModel") {
            el.components = el.components
              .map((co) => {
                co.images = co.images.filter((im) => im.active && im.show);
                return !co.images.length ? null : co;
              })
              .filter((co) => !!co);
            return !el.components.length ? null : el;
          }
          if (el.jsClass === "ElementTextModel") {
            el.components = el.components.filter((font) => font.active);
            return !el.components.length ? null : el;
          }
        })
        .filter((el) => !!el);
      return customization;
    },
  },
};
</script>

<style lang="scss" scoped>
.export-component {
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  border: 0;
  background-color: #444;
  border-radius: 4px;
  color: #fff;
  font-family: "Montserrat", helvetica neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 0 18px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }

  &--disabled {
    opacity: 0.5;
    cursor: no-drop;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin-right: 5px;
    width: 16px;
    fill: white;
    display: block;
  }
}
</style>
