<template>
  <div
    v-if="!!width && !!height && available"
    :class="[
      'component-text',
      {
        'component-text--selected':
          $store &&
          !!$store.state.currentElement &&
          element.id === $store.state.currentElement.id,
        'selected-component':
          $store &&
          !!$store.state.currentElement &&
          element.id === $store.state.currentElement.id,
      },
    ]"
    :data-component-id="element.id"
    :style="groupStyle"
    @click="selectElement"
  >
    <div
      :data-label="labelDefault"
      class="component-text__item"
      :style="containerStyle"
      ref="container"
    >
      <span v-if="element.shadowEnabled" ref="shadow" :style="shadowStyle">{{
        text
      }}</span>
      <span ref="text" :style="textStyle">{{ text }}</span>
      <span ref="textReference" :style="textReferenceStyle">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    font: Object,
    previewContext: Object,
    showTextBorder: Boolean,
  },
  data() {
    return {
      width: null,
      height: null,
      fontScale: 1,
      available: false,
    };
  },
  computed: {
    labelDefault() {
      return this.element.label.values.default;
    },
    text() {
      const { element, font } = this;
      let t = element.value === "" ? element.placeholder : element.value;
      t = t.replace(/(^[\s]+|[\s]+$)/g, "");
      if (t !== "") t = `${font.prefix}${t}${font.sufix}`;
      return t;
    },
    calcs() {
      const { element, width, height, previewContext, font, available } = this;
      if (!element || !width || !height || !previewContext || !available)
        return;
      const { scaleX, scaleY } = this.getScales(
        element,
        width,
        height,
        previewContext
      );

      const { boxWidth, boxHeight } = this.boxSize(
        width * scaleX,
        height * scaleY,
        element.rotation
      );

      // eslint-disable-next-line
      return {
        element,
        width,
        height,
        previewContext,
        scaleX,
        scaleY,
        boxWidth,
        boxHeight,
        font,
      };
    },
    groupStyle() {
      const { element, boxWidth, boxHeight, previewContext } = this.calcs;

      let left, right;
      switch (element.alignX) {
        case "center":
          left = previewContext.width / 2 - boxWidth / 2;
          left += element.mx * previewContext.width;
          break;
        case "left":
          left = 0;
          left += element.mx * previewContext.width;
          break;
        case "right":
          right = 0;
          right -= element.mx * previewContext.width;
          break;
      }

      let top, bottom;
      switch (element.alignY) {
        case "middle":
          top = previewContext.height / 2 - boxHeight / 2;
          top += element.my * previewContext.height;
          break;
        case "top":
          top = 0;
          top += element.my * previewContext.height;
          break;
        case "bottom":
          bottom = 0;
          bottom -= element.my * previewContext.height;
          break;
      }

      return {
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        top: `${top + "px"}`,
        bottom: `${bottom + "px"}`,
        left: `${left + "px"}`,
        right: `${right + "px"}`,
      };
    },
    containerStyle() {
      const { showTextBorder } = this;
      const { element, width, height, scaleX, scaleY, font } = this.calcs;
      let whiteSpace, alignItems;

      switch (element.wrapText) {
        case "none":
          whiteSpace = "nowrap";
          break;
      }
      switch (element.verticalAlign) {
        case "top":
          alignItems = "flex-start";
          break;
        case "middle":
          alignItems = "center";
          break;
        case "bottom":
          alignItems = "flex-end";
          break;
        default:
          alignItems = "flex-start";
          break;
      }

      return {
        width: `${Math.ceil(width * scaleX)}px`,
        height: `${Math.ceil(height * scaleY)}px`,
        transform: `rotate(${element.rotation}deg)`,
        fontFamily: font.identifier,
        textAlign: element.alignText,
        whiteSpace,
        alignItems,
        boxShadow: showTextBorder ? `0px 0px 0px 5px black` : null,
      };
    },
    textReferenceStyle() {
      const { scaleX, font } = this.calcs;
      return {
        position: `absolute`,
        opacity: 0,
        fontSize: `${Math.ceil(font.fontSize * scaleX)}px`,
        textTransform: font.caseType,
      };
    },
    fontSize() {
      const { scaleX, font } = this.calcs;
      const { fontScale } = this;
      return Math.ceil(font.fontSize * scaleX * fontScale);
    },
    shadowStyle() {
      const { element, scaleX, scaleY, font } = this.calcs;
      const { fontSize } = this;
      const i = element.colors.indexOf(element.color);
      const color = element.shadowColors[i];

      const shadowOffsetX = Math.ceil(element.shadowOffsetX * scaleX);
      const shadowOffsetY = Math.ceil(element.shadowOffsetY * scaleY);
      const shadowBlur = Math.ceil(element.shadowBlur);
      const textShadow = `${shadowOffsetX}px ${shadowOffsetY}px ${shadowBlur}px ${
        color ? color : "transparent"
      }`;

      return {
        position: `absolute`,
        fontSize: `${fontSize}px`,
        lineHeight: `${fontSize}px`,
        width: "100%",
        color,
        textTransform: font.caseType,
        textShadow,
        opacity: element.shadowOpacity,
      };
    },
    textStyle() {
      const { element, font } = this.calcs;
      const { fontSize, available } = this;
      if (!available) return;
      const i = element.colors.indexOf(element.color);
      const strokeColor = element.strokeColors[i];
      const stroke = element.strokeEnabled
        ? this.calculateStrokeTextCSS(16, element.strokeWidth, strokeColor)
        : null;
      return {
        position: `absolute`,
        fontSize: `${fontSize}px`,
        lineHeight: `${fontSize}px`,
        width: "100%",
        color: element.color,
        textShadow: !!strokeColor ? stroke : null,
        textTransform: font.caseType,
      };
    },
  },
  watch: {
    text() {
      this.resize();
    },
    font: {
      deep: true,
      handler() {
        this.loadFont(this.font);
      },
    },
  },
  methods: {
    selectElement() {
      this.$store.dispatch("setCurrentElement", {
        element: this.element,
      });
    },
    boxSize(width, height, rotation) {
      const cos = Math.cos(rotation * (Math.PI / 180));
      const sin = Math.sin(rotation * (Math.PI / 180));

      return {
        boxWidth: Math.ceil(Math.abs(width * cos) + Math.abs(height * sin)),
        boxHeight: Math.ceil(Math.abs(width * sin) + Math.abs(height * cos)),
      };
    },
    getScales(element, width, height, previewContext) {
      let scaleWidth = 1,
        scaleHeight = 1;
      switch (element.applyScale) {
        case "default":
          scaleWidth = previewContext.width / width;
          scaleHeight = scaleWidth;
          break;
        case "context":
          scaleWidth = previewContext.width / width;
          scaleHeight = previewContext.height / height;
          break;
        case "real":
          scaleWidth = 1;
          scaleHeight = 1;
          break;
      }
      if (width === height) scaleHeight = scaleWidth;

      return {
        scaleX: scaleWidth * element.scaleX,
        scaleY: scaleHeight * element.scaleY,
      };
    },
    calculateStrokeTextCSS(steps, width, color) {
      var css = "";
      for (var i = 0; i < steps; i++) {
        var angle = (i * 2 * Math.PI) / steps;
        var cos = Math.round(10000 * Math.cos(angle)) / 10000;
        var sin = Math.round(10000 * Math.sin(angle)) / 10000;
        css += `${width * cos}px ${width * sin}px 0 ${color} ${
          i + 1 < steps ? "," : ""
        }`;
      }

      return css;
    },
    resize() {
      this.$nextTick(() => {
        const { textReference, container } = this.$refs;
        if (
          textReference &&
          container &&
          textReference.offsetWidth > container.offsetWidth
        )
          this.fontScale = container.offsetWidth / textReference.offsetWidth;
        else this.fontScale = 1;
      });
    },
    loadFont({ identifier, src }) {
      const chargeFont = new window.FontFace(identifier, `url(${src})`);
      if (!document || !document.fonts || !document.fonts.add)
        return Promise.reject();
      return chargeFont.load().then((loaded) => document.fonts.add(loaded));
    },
    boot() {
      return this.loadFont(this.font)
        .then(() => {
          this.available = true;
          this.width = this.element.width;
          this.height = this.element.height;
          this.resize();
        })
        .catch(() => (this.available = false));
    },
  },
  mounted() {
    return this.boot();
  },
};
</script>

<style lang="scss" scoped>
.component-text {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    position: absolute;
    display: flex;
  }
}

.component-text--selected .component-text__item {
  border: 5px dotted red;
}
</style>
