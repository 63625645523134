<template>
  <div class="image-editor__filters" v-if="showFilters">
    <div class="image-editor__filters-list">
      <figure
        class="image-editor__filters-figure"
        v-for="preset in presets"
        :key="preset.name"
        style="position: relative"
        :style="figureStyle(preset)"
        @click="$emit('filter', format(preset))"
        v-show="available"
      >
        <div
          class="image-editor__filters-overlay"
          v-for="(overlay, i) in !!preset.overlays ? preset.overlays : []"
          :key="i"
          :style="{
            'mix-blend-mode': overlay.mixBlendMode,
            background: overlay.color,
            opacity: overlay.opacity,
          }"
        ></div>
        <div>
          <img :src="image" @load="loaded" />
        </div>
      </figure>
    </div>
  </div>
</template>

<script>
import Presets from "./Filters.js";

export default {
  props: { image: String },
  data() {
    return { presets: null, available: false };
  },
  computed: {
    showFilters() {
      return !/^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
  },
  methods: {
    loaded() {
      this.available = true;
    },
    figureStyle(preset) {
      const filter = this.formatValues(preset.values);
      return {
        "-webkit-filter": filter,
        "-moz-filter": filter,
        "-o-filter": filter,
        "-ms-filter": filter,
        filter,
      };
    },
    format(preset) {
      return {
        preset,
        style: this.figureStyle(preset),
        overlays: preset.overlays,
        value: this.formatValues(preset.values),
      };
    },
    formatValues(v) {
      return `sepia(${v.sepia}) contrast(${v.contrast}) brightness(${v.brightness}) grayscale(${v.grayscale}) saturate(${v.saturate}) opacity(${v.opacity}) invert(${v.invert}) blur(${v.blur}) drop-shadow(${v.dropShadow} ${v.dropShadowColor}) hue-rotate(${v.hueRotate})`;
    },
  },
  mounted() {
    this.presets = Presets.values();
  },
};
</script>

<style lang="scss" scoped>
.image-editor__filters {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.image-editor__filters-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 60px);
  grid-auto-rows: 1fr;
  grid-gap: 12px;
  position: relative;
  padding: 10px 12px;
  width: min-content;
  height: 60px;

  .image-editor__filters-figure {
    cursor: pointer;
    grid-row: 1;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    margin: 0;
    overflow: hidden;

    img {
      width: 100%;
      pointer-events: none;
    }
  }

  .image-editor__filters-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
</style>
