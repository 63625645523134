<template>
  <div class="prisma-controls">
    <button @click="controlTop" class="prisma-control prisma-control--top">
      ↑
    </button>
    <button
      @click="controlBottom"
      class="prisma-control prisma-control--bottom"
    >
      ↓
    </button>
    <button @click="controlRight" class="prisma-control prisma-control--right">
      →
    </button>
    <button @click="controlLeft" class="prisma-control prisma-control--left">
      ←
    </button>
    <button
      @click="controlRotateLeft"
      class="prisma-control prisma-control--rotate-left"
    >
      ↺
    </button>
    <button
      @click="controlRotateRight"
      class="prisma-control prisma-control--rotate-right"
    >
      ↻
    </button>
    <button
      @click="controlZoomPlus"
      class="prisma-control prisma-control--plus"
    >
      +
    </button>
    <button
      @click="controlZoomLess"
      class="prisma-control prisma-control--less"
    >
      -
    </button>
  </div>
</template>

<script>
export default {
  mounted() {
    const qs = document.querySelector.bind(document);
    const previewContainer = () =>
      qs(".mockup-preview__container") || qs(".preview-component");

    this.$nextTick(() => {
      let mockupPreview = previewContainer();

      if (mockupPreview) {
        mockupPreview.append(this.$el);
      } else {
        setTimeout(() => {
          mockupPreview = previewContainer();
          if (mockupPreview) mockupPreview.append(this.$el);
        }, 500);
      }
    });
  },
  methods: {
    dispatchControl(controlCode) {
      window.dispatchEvent(
        new CustomEvent(`prisma:control:${controlCode}`, {
          detail: { componentId: this.selectedComponentId() },
        })
      );
    },
    controlTop() {
      this.dispatchControl("top");
    },
    controlBottom() {
      this.dispatchControl("bottom");
    },
    controlRight() {
      this.dispatchControl("right");
    },
    controlLeft() {
      this.dispatchControl("left");
    },
    controlRotateRight() {
      this.dispatchControl("rotate-right");
    },
    controlRotateLeft() {
      this.dispatchControl("rotate-left");
    },
    controlZoomPlus() {
      this.dispatchControl("zoom-plus");
    },
    controlZoomLess() {
      this.dispatchControl("zoom-less");
    },
    selectedComponentId() {
      return Number(
        document.querySelector(".selected-component").dataset.componentId || 0
      );
    },
  },
};
</script>

<style scoped>
.prisma-controls {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  z-index: 10;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.prisma-control {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #d2d2d6;
  color: #333;
  background: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
}

.prisma-control--rotate-left,
.prisma-control--rotate-right {
}

.prisma-control:active {
  transform: scale(1.1);
}
</style>
