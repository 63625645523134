var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.element.hideInput)?_c('div',{staticClass:"text-component",style:({
    display: _vm.labelDefault === 'Answer AI' ? 'none' : '',
  }),attrs:{"data-label":_vm.labelDefault,"data-question":_vm.element.name}},[_c('h3',{staticClass:"text-component__label",attrs:{"data-label":_vm.$t('labelText')}}),(!_vm.validate)?_c('div',{staticClass:"text-component--alert"},[_vm._v(" "+_vm._s(_vm.$t("hintInputText"))+" ")]):_vm._e(),_c('div',{staticClass:"text-component__input"},[_c('div',{staticClass:"text-component__input-container"},[(!_vm.colorOpen)?_c('input-component',{staticClass:"text-component__input-element",attrs:{"element":_vm.element,"disabled":_vm.removedCustomization},on:{"update":_vm.update}}):_vm._e()],1),(_vm.showColors)?_c('colors-component',{attrs:{"colors":_vm.element.colors,"color":_vm.element.color},on:{"color":_vm.setNewColor}}):_vm._e(),(_vm.showFonts)?_c('fonts-component',{attrs:{"components":_vm.element.components}}):_vm._e(),_c('div',{staticClass:"text-component__ai-actions"},[(_vm.element.showDiscoverAiBtn && _vm.$i18n.locale !== 'ptbr')?_c('select',{staticClass:"text-component__language",attrs:{"id":"language"}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("🌐 "+_vm._s(_vm.$t("select_a_language")))]),_c('option',{attrs:{"value":"French"}},[_vm._v("French")]),_c('option',{attrs:{"value":"German"}},[_vm._v("German")]),_c('option',{attrs:{"value":"Italian"}},[_vm._v("Italian")]),_c('option',{attrs:{"value":"Spanish"}},[_vm._v("Spanish")]),_c('option',{attrs:{"value":"English"}},[_vm._v("English")]),_c('option',{attrs:{"value":"Portuguese"}},[_vm._v("Portuguese")])]):_vm._e(),(_vm.element.showDiscoverAiBtn)?_c('button',{staticClass:"text-component__action-btn text-component__action-btn--ai",attrs:{"disabled":_vm.loadingAnswer},on:{"click":_vm.generateAnswer}},[(_vm.loadingAnswer)?_c('span',[_c('three-dots-loading')],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t("discover_name"))+" ")])]):(_vm.labelDefault !== 'Question AI')?_c('button',{class:[
          'text-component__action-btn',
          {
            'text-component__action-btn--active': _vm.removedCustomization,
          },
        ],attrs:{"type":"button"},on:{"click":_vm.removePlaceholder}},[(_vm.removedCustomization)?_c('span',{staticClass:"text-component__action-btn-icon"},[_c('icon-cross-pencil')],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("removeCustomization"))+" ")]):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }