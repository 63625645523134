import axios from "axios";

const REMOVE_BG_API_URL =
  "https://www.cutout.pro/api/v1/matting?mattingType=3&crop=true";
const REMOVE_BG_API_KEY = "bc1668fd1eb9445ab563d55b2829a59d";

const log = (...args) => console.log(`[img-bg]`, ...args);

async function headExtract(image_file) {
  const formData = new FormData();
  formData.append("file", image_file);
  // formData.append("format", "png");

  const response = await axios.post(REMOVE_BG_API_URL, formData, {
    headers: {
      APIKEY: REMOVE_BG_API_KEY,
    },
    responseType: "blob",
  });

  log(`Response status:`, response.status);

  console.log(response, "response");

  const blob = await response.data;
  return blob;
}

export function previewBlobImg(blob) {
  const objectURL = URL.createObjectURL(blob);
  window.open(objectURL);
}

export default headExtract;
