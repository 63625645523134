<template>
  <div class="e-picker-component">
    <h3
      class="e-picker-component__element-label"
      :data-label="`${label(element.label.values)} ${aditionalLabel}`"
    />

    <template v-if="availableComponents.length <= 3">
      <div
        v-for="component in availableComponents"
        :key="component.identifier"
        class="e-picker-component__item"
      >
        <h4
          v-if="availableComponents.length > 1"
          class="e-picker-component__component-label"
        >
          {{ label(component.label.values) }}
        </h4>

        <image-editor-component
          v-if="component && component.canUploadFile"
          :component="component"
          :identifier="identifier"
        >
          <template v-if="$store.state.removingBg">
            <div class="e-picker-component__file">
              <three-dots-loading />
            </div>
          </template>
          <template v-else>
            <div class="e-picker-component__file">
              <icon-picture />
              <span>{{ $t("choosePhoto") }}</span>
            </div>
          </template>
        </image-editor-component>

        <picker-items-list-component
          v-if="
            component &&
            !(component.canUploadFile && component.images.length === 1)
          "
          :show-button="component.images.length > showedItemsLength"
          :showed-items-length="showedItemsLength"
          :opened="opened(component)"
          @click="() => $emit('change', { component })"
        >
          <template v-slot:container>
            <picker-item-component
              v-for="image in componentImages(
                component.images,
                opened(component)
              )"
              :key="image.key"
              :image="image"
              :component="component"
              @click="pickItem"
            />
          </template>
        </picker-items-list-component>
      </div>
    </template>
    <template v-else>
      <picker-item-multi-component
        :available-components="availableComponents"
        :showed-items-length="showedItemsLength"
        :opened-component="openedComponent"
        :label="label"
        @label="(v) => (aditionalLabel = v)"
        @pickItem="pickItem"
        @change="(component) => $emit('change', { component })"
      />
    </template>
  </div>
</template>

<script>
import ImageEditorComponent from "@/components/image-editor-component/ImageEditorComponent.vue";
import IconPicture from "@gocasebr/go-shared/dist/picture/Picture";
import PickerItemComponent from "@/components/picker-component/picker-item-component/PickerItemComponent.vue";
import PickerItemsListComponent from "@/components/picker-component/picker-items-list-component/PickerItemsListComponent.vue";
import PickerItemMultiComponent from "@/components/picker-component/picker-item-multi-component/PickerItemMultiComponent.vue";
import ThreeDotsLoading from "../three-dots-loading/ThreeDotsLoading.vue";

export default {
  components: {
    ImageEditorComponent,
    IconPicture,
    PickerItemComponent,
    PickerItemsListComponent,
    PickerItemMultiComponent,
    ThreeDotsLoading,
  },
  props: {
    element: Object,
    language: String,
    openedComponent: Object,
    elements: Array,
    identifier: String,
  },
  data() {
    return {
      showedItemsLength: window.innerWidth <= 600 ? 6 : 7,
      aditionalLabel: "",
      alreadySelectedOnStart: false,
    };
  },
  computed: {
    availableComponents() {
      return this.element.components.filter(
        (c) => c.images.length > 1 || c.canUploadFile
      );
    },
  },
  methods: {
    opened(component) {
      return this.openedComponent === component;
    },
    pickItem({ component, image }) {
      this.setActiveAndShowForImages(component.images, image);

      this.updateVisibilityBasedOnComponentHideList(
        this.element.components,
        component.hideComponentsById
      );

      // Handling rapport if exists
      if (this.element.rapport) {
        this.updateRapportComponent(
          this.elements,
          this.element,
          component,
          image
        );
      }

      component.runUpdate = true;
      this.element.runUpdate = true;
    },
    setActiveAndShowForImages(images, selectedImage) {
      images.forEach((image) => {
        image.active = image.id === selectedImage.id;
        image.show = true;
      });
    },
    updateVisibilityBasedOnComponentHideList(components, hideComponentsById) {
      const shouldHide = (id) => hideComponentsById.includes(id);
      components.forEach((component) => {
        component.images.forEach((image) => {
          image.show = !shouldHide(component.identifier);
        });
      });
    },
    updateRapportComponent(elements, element, component, image) {
      const rapportElement = elements.find(
        (e) => e.rapport && e.identifier === `${element.identifier}rapport`
      );
      if (!rapportElement) return;

      const rapportComponent = rapportElement.components.find(
        (c) => c.id === component.id
      );
      if (!rapportComponent) return;

      this.setActiveAndShowForImages(rapportComponent.images, image);

      this.updateVisibilityBasedOnComponentHideList(
        rapportElement.components,
        rapportComponent.hideComponentsById
      );
    },
    label(label) {
      const code = this.language.includes("ptbr") ? "pt-br" : this.language;

      let l = label[code];
      !l && (l = label.default);

      return l;
    },
    labelText(elementValues, componentValues) {
      const elementLabel = this.label(elementValues);
      const componentLabel = this.label(componentValues);
      if (elementLabel.toLowerCase() === componentLabel.toLowerCase())
        return elementLabel;
      return `${elementLabel} - ${componentLabel}`;
    },
    imageUrl(url) {
      return url.replace("http://", "https://");
    },
    componentImages(images, showAll) {
      const active = images.find(({ active, show }) => active && show);
      const others = images.filter((item) => item !== active);
      const formattedImages =
        !showAll && images.indexOf(active) > this.showedItemsLength
          ? [active, ...others].filter((item) => item)
          : images;

      if (showAll) return formattedImages;
      if (formattedImages.length === this.showedItemsLength)
        return formattedImages;
      else if (formattedImages.length > this.showedItemsLength)
        return formattedImages.filter((_, i) => i < this.showedItemsLength - 1);
      return formattedImages;
    },
  },
};
</script>

<style lang="scss" scoped>
.e-picker-component {
  display: grid;
  gap: 4px;
  font-family: sans-serif;

  &__item {
    display: grid;
    gap: 4px;
    padding: 2px 0;
  }

  &__element-label,
  &__component-label {
    font-size: 12px;
    font-weight: normal;
    color: #666;
    margin: 0;
    height: 14px;
    line-height: 14px;
    width: 100%;
    position: relative;
    text-transform: capitalize;
  }

  &__component-label {
    margin-left: 2px;
  }

  &__element-label {
    padding: 0 10px;
    font-weight: bold;
    text-transform: uppercase;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #ddd;
      left: 0;
      top: 50%;
      position: absolute;
    }

    &:after {
      content: attr(data-label);
      display: block;
      position: absolute;
      background-color: #fff;
      padding: 0 4px;
      top: 0;
    }
  }

  &__file {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.05);
    border: 0;
    text-align: center;
    color: #222;
    padding: 17px 6px;
    border-radius: 4px;
    width: 100%;
    margin: 0;
    box-shadow: inset 0 -3px 0 rgb(0 0 0 / 10%);
    margin-top: 4px;
    box-sizing: border-box;

    svg {
      width: 16px;
      fill: #222;
      display: block;
      margin-right: 6px;
    }
  }

  .e-bg-squad {
    background: url("https://static-production.gocase.com.br/transparency.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
