export default class Presets {
  static values() {
    return [
      {
        name: "none",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0, contrast: 1, brightness: 1, grayscale: 0, saturate: 1, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
      {
        name: "1977",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0, contrast: 1.1, brightness: 1.1, grayscale: 0, saturate: 1.3, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [
          {
            opacity: 0.5,
            color: "rgba(243, 106, 188, 0.3)",
            mixBlendMode: "screen",
          },
        ],
      },
      {
        name: "Valencia",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0.08, contrast: 1.08, brightness: 1.08, grayscale: 0, saturate: 1, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [
          {
            opacity: 0.5,
            color: "rgba(58, 3, 57, 1)",
            mixBlendMode: "exclusion",
          },
        ],
      },
      {
        name: "sepia",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 1, contrast: 1, brightness: 1, grayscale: 0, saturate: 1, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
      {
        name: "grayscale",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0, contrast: 1, brightness: 1, grayscale: 1, saturate: 1, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
      {
        name: "watercolor",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0.25, contrast: 1, brightness: 1.3, grayscale: 0, saturate: 2.6, opacity: 1, invert: 0.17, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
      {
        name: "faded-photo",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0.4, contrast: 1, brightness: 1.1, grayscale: 0, saturate: 1.3, opacity: 0.9, invert: 0, blur: `${0.2}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${5}deg` },
        overlays: [],
      },
      {
        name: "old-horror",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0.5, contrast: 1, brightness: 1.3, grayscale: 1, saturate: 1, opacity: 1, invert: 0.8, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
      {
        name: "old-grainy",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0.5, contrast: 1, brightness: 1.5, grayscale: 0.6, saturate: 1, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
      {
        name: "fade-out",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0, contrast: 0.6, brightness: 0.8, grayscale: 0, saturate: 3, opacity: 1, invert: 0, blur: `${8}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${350}deg` },
        overlays: [],
      },
      {
        name: "mist",
        description: "",
        // eslint-disable-next-line
        values: { sepia: 0, contrast: 1, brightness: 0.8, grayscale: 0, saturate: 0.8, opacity: 1, invert: 0, blur: `${0}px`, dropShadow: `${0}px ${0}px ${0}px`, dropShadowColor: ``, hueRotate: `${0}deg` },
        overlays: [],
      },
    ];
  }
}
