/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");

const getAnswer = (question) =>
  httpClient
    .get(`/public/ai/openai/ask?input=${question}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export { getAnswer };
