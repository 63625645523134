const compareErrors = (error, otherError) =>
  JSON.stringify(error) === JSON.stringify(otherError);

export default {
  state: {
    errors: [],
    currentElement: null,
    enabledControls: false,
    patternModeActive: false,
    patternEngineName: "",
  },
  mutations: {
    addElementError(state, { element, error }) {
      state.errors.push({ element, error });
    },
    removeElementError(state, { element, error }) {
      state.errors = state.errors.filter(
        (e) => !compareErrors(e, { element, error })
      );
    },
    setCurrentElement(state, { element }) {
      state.currentElement = element;
    },
    enableControls(state, enabled) {
      state.enabledControls = enabled;
    },
    setPatternEngineName(state, text) {
      state.patternEngineName = text;
    },
    activePatternMode(state, active) {
      state.patternModeActive = active;
    },
  },
  actions: {
    addElementError({ commit }, payload) {
      commit("addElementError", payload);
    },
    removeElementError({ commit }, payload) {
      commit("removeElementError", payload);
    },
    setCurrentElement({ commit }, payload) {
      commit("setCurrentElement", payload);
    },
    enableControls({ commit }) {
      commit("enableControls", true);
    },
    disableControls({ commit }) {
      commit("enableControls", false);
    },
    setPatternEngineName({ commit }, name) {
      commit("setPatternEngineName", name);
    },
    activePatternMode({ commit }) {
      commit("activePatternMode", true);
    },
    disablePatternMode({ commit }) {
      commit("activePatternMode", false);
    },
  },
  getters: {
    emptyErrors: (state) => {
      return state.errors.length === 0;
    },
    elementErrors: (state) => (otherElement) => {
      return state.errors.filter(({ element }) => element === otherElement);
    },
  },
};
