var render = function render(){var _vm=this,_c=_vm._self._c;return (!!_vm.width && !!_vm.height && _vm.available)?_c('div',{class:[
    'component-text',
    {
      'component-text--selected':
        _vm.$store &&
        !!_vm.$store.state.currentElement &&
        _vm.element.id === _vm.$store.state.currentElement.id,
      'selected-component':
        _vm.$store &&
        !!_vm.$store.state.currentElement &&
        _vm.element.id === _vm.$store.state.currentElement.id,
    },
  ],style:(_vm.groupStyle),attrs:{"data-component-id":_vm.element.id},on:{"click":_vm.selectElement}},[_c('div',{ref:"container",staticClass:"component-text__item",style:(_vm.containerStyle),attrs:{"data-label":_vm.labelDefault}},[(_vm.element.shadowEnabled)?_c('span',{ref:"shadow",style:(_vm.shadowStyle)},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('span',{ref:"text",style:(_vm.textStyle)},[_vm._v(_vm._s(_vm.text))]),_c('span',{ref:"textReference",style:(_vm.textReferenceStyle)},[_vm._v(_vm._s(_vm.text))])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }