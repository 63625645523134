/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");

const getInitials = () =>
  httpClient
    .get(`/initials`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const getInitialGroup = ({ name, store }) =>
  httpClient
    .get(`/initials/${name}/${store}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export { getInitials, getInitialGroup };
