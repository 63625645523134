// Default
import Vue from "vue";
import store from "@/store";
import i18n from "@/plugins/i18n/i18n.js";
import "@/plugins/airbrake.js";
import Hammer from "hammerjs";
// Engine
import Engine from "@/Engine.vue";

// API
import Api from "@/api/api.js";

Api.update({
  CATALOG_API: process.env.VUE_APP_CATALOG_API_V2,
  FACTORY_API: process.env.VUE_APP_FACTORY_API,
  PRISMA_RENDER_API: process.env.VUE_APP_PRISMA_RENDER_API,
});

Vue.directive("pan", {
  bind: function (el, binding) {
    if (typeof binding.value === "function") {
      const mc = new Hammer(el);
      mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });
      mc.on("pan", binding.value);
    }
  },
});

Vue.config.productionTip = false;

let vm_prisma = null;

function loadMaterial() {
  const app = vm_prisma.$children.find(
    (child) => child.$options.name === "engine"
  );
  app.updateMaterial();
}

export function bootstrapEngine(options) {
  if (!options.language) options.language = document.documentElement.lang;

  options.language = options.language.replace(/[^a-z0-9]/gi, "").toLowerCase();
  i18n.locale = options.language;

  if (!options.imageKitHost)
    options.imageKitHost = process.env.VUE_APP_IMAGE_KIT_HOST;
  if (!options.cloudflareHost)
    options.cloudflareHost = process.env.VUE_APP_CLOUDFLARE_HOST;

  Vue.prototype.$settings = options;

  if (!vm_prisma) {
    vm_prisma = new Vue({
      i18n,
      store,
      render: (h) => h(Engine),
    }).$mount(`#${options.appMountId}`);
  } else loadMaterial();
}

//TODO: Export to bootstrap window
window.bootstrapEngine = bootstrapEngine;

window.prisma = {
  boot: bootstrapEngine,
  version: require("../package.json").version,
};
