<template>
  <ul class="image-editor__nav">
    <li class="image-editor__nav-li" @click="emit('rotate', 'l')">
      <icon-rotate
        class="image-editor__nav-li-svg"
        style="transform: scaleX(-1)"
      />
      <span>Rotate L</span>
    </li>
    <li class="image-editor__nav-li" @click="emit('rotate', 'r')">
      <icon-rotate class="image-editor__nav-li-svg" />
      <span>Rotate R</span>
    </li>
    <li class="image-editor__nav-li" @click="emit('scale', 'i')">
      <icon-zoom-in class="image-editor__nav-li-svg" />
      <span>Zoom I</span>
    </li>
    <li class="image-editor__nav-li" @click="emit('scale', 'o')">
      <icon-zoom-out class="image-editor__nav-li-svg" />
      <span>Zoom O</span>
    </li>
    <li class="image-editor__nav-li" @click="emit('flip', 'h')">
      <icon-flip-h class="image-editor__nav-li-svg" />
      <span>Flip H</span>
    </li>
    <li class="image-editor__nav-li" @click="emit('flip', 'v')">
      <icon-flip-v class="image-editor__nav-li-svg" />
      <span>Flip V</span>
    </li>
    <li class="image-editor__nav-li image-editor__done" @click="$emit('done')">
      <icon-check class="image-editor__nav-li-svg" />
      <span class=""> {{ $t("done") }} </span>
    </li>
  </ul>
</template>

<script>
import IconCheck from "@gocasebr/go-shared/dist/check/Check";
import IconRotate from "@gocasebr/go-shared/dist/rotate/Rotate";
import IconZoomIn from "@gocasebr/go-shared/dist/zoom-in/ZoomIn";
import IconZoomOut from "@gocasebr/go-shared/dist/zoom-out/ZoomOut";
import IconFlipH from "@gocasebr/go-shared/dist/flip-h/FlipH";
import IconFlipV from "@gocasebr/go-shared/dist/flip-v/FlipV";

export default {
  components: {
    IconCheck,
    IconRotate,
    IconZoomIn,
    IconZoomOut,
    IconFlipH,
    IconFlipV,
  },
  data() {
    return {
      i: 0,
    };
  },
  methods: {
    emit(event, value) {
      this.i++;
      this.$emit(event, { value, i: this.i });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-editor__nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 10px;
  background: #333;

  .image-editor__nav-li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: #fff;
    color: #fff;
    cursor: pointer;
    padding: 12px 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */

    &:hover {
      background: rgba($color: #222, $alpha: 0.6);
    }
  }

  .image-editor__done {
    background: #f37053;

    &:hover {
      background: rgba($color: #f37053, $alpha: 0.8);
    }
  }

  .image-editor__nav-li-svg {
    width: 45%;
    max-width: 26px;
    margin-bottom: 6px;
  }
}
</style>
