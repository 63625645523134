import { uploadImage } from "./uploadImage.js";
import axios from "axios";

const REMOVE_BG_API_URL = "https://sdk.photoroom.com/v1/segment";
const REMOVE_BG_API_KEY = "b70c6f0ebe11c8e567d861e670869a277c53d157";
const GOCASE_REMOVE_BG_API_URL =
  "https://go-remove-bg-gpu.gocase.com.br/api/remove";

const log = (...args) => console.log(`[img-bg]`, ...args);

async function convertImageFileToUrl(file) {
  const { src } = await uploadImage({ image: file });
  return src;
}

async function removeImageBg(
  image_file,
  provider = "photoroom",
  identifier = "empty"
) {
  let response = null;

  if (provider === "photoroom") {
    const formData = new FormData();
    formData.append("image_file", image_file);
    formData.append("format", "png");

    response = await axios.post(REMOVE_BG_API_URL, formData, {
      responseType: "blob",
      headers: {
        "x-api-key": REMOVE_BG_API_KEY,
      },
    });
  } else {
    const formData = new FormData();
    formData.append("file", image_file);
    formData.append("format", "png");
    formData.append("sku", identifier);
    formData.append("model", "u2netp");

    response = await axios.post(GOCASE_REMOVE_BG_API_URL, formData, {
      responseType: "blob",
    });
  }

  log(`Response status:`, response.status);

  const imgBlob = await response.data;

  return imgBlob;
}

export function previewBlobImg(blob) {
  const objectURL = URL.createObjectURL(blob);
  window.open(objectURL);
}

export default removeImageBg;
