var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.image)?_c('div',{class:[
    'component-image',
    {
      'component-image--selected':
        _vm.$store &&
        !!_vm.$store.state.currentElement &&
        _vm.element.id === _vm.$store.state.currentElement.id,
      'selected-component':
        _vm.$store &&
        !!_vm.$store.state.currentElement &&
        _vm.element.id === _vm.$store.state.currentElement.id,
    },
  ],style:(_vm.groupStyle),attrs:{"data-component-id":_vm.element.id},on:{"click":_vm.selectElement}},[_c('img',{staticClass:"component-image__item",style:(_vm.imageStyle),attrs:{"src":_vm.image.src}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }