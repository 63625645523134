/* istanbul ignore file */
const { httpClient } = require("../catalog.api.js");
const packageJson = require("../../../../package.json");
const prismaVersion = packageJson.version;

// TODO AFTER MIGRATION FINISH
//CATALOGV1
// const getCustomization = ({ identifier, material, store }) =>
//   httpClient
//     .get(`/design_customization`, {
//       params: { identifier, material, store },
//     })
//     .then((response) =>
//       !response.data.length
//         ? response.data
//         : getCustomizationLegacy({ identifier, material })
//     )
//     .catch(() => getCustomizationLegacy({ identifier, material }));

//CATALOGV2
const getCustomization = async ({ identifier, material, store }) => {
  try {
    const versionResponse = await httpClient.get(
      `/public/design_customizations/version/${identifier}`,
      { params: { material, store } }
    );
    const version = versionResponse.data.version;

    const customizationResponse = await httpClient.get(
      `/public/design_customizations/${identifier}`,
      {
        params: { material, store, version, prisma_version: prismaVersion },
      }
    );

    return customizationResponse.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO AFTER MIGRATION FINISH
const getCustomizationLegacy = ({ identifier, material }) =>
  httpClient
    .get(
      `https://go-catalog-api.herokuapp.com/api/v1/design_customizations/get_by_identifier_material`,
      { params: { identifier, material } }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const getCustomizations = ({ perPage, page, identifier }) =>
  httpClient
    .get(`/design_customizations`, {
      params: { perPage, page, identifier },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const saveCustomization = (design_customization) =>
  httpClient
    .post(
      `/design_customization`,
      { design_customization },
      { "Content-Type": "application/json" }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const destroyCustomization = ({ id }) =>
  httpClient
    .delete(`/design_customization/${id}`, {
      headers: {
        Authorization: "***",
      },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const getPreviewsCustomizations = (identifier) =>
  httpClient
    .get(`/design_customizations/get_previews?identifier=${identifier}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

const updatePreviewsCustomization = ({ id, preview, previewPt, previewEn }) =>
  httpClient
    .post(
      `/design_customization/update_previews`,
      { id, preview, previewPt, previewEn },
      { "Content-Type": "application/json" }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

// TODO AFTER MIGRATION FINISH
const getCustomizationStructure = ({ identifier }) =>
  httpClient
    .get(`/design_customization/design_customization_structure`, {
      params: { identifier },
    })
    .then((response) =>
      !response.data.length
        ? response.data
        : getCustomizationStructureLegacy({ identifier })
    )
    .catch(() => getCustomizationStructureLegacy({ identifier }));

// TODO AFTER MIGRATION FINISH
const getCustomizationStructureLegacy = ({ identifier }) =>
  httpClient
    .get(
      `https://go-catalog-api.herokuapp.com/api/v1/design_customization/design_customization_structure`,
      { params: { identifier } }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export {
  getCustomization,
  getCustomizations,
  saveCustomization,
  destroyCustomization,
  getPreviewsCustomizations,
  updatePreviewsCustomization,
  getCustomizationStructure,
};
