/* istanbul ignore file */
const { httpClient } = require("../prismaRender.api.js");

const generate3dImage = (data) =>
  httpClient
    .post(
      `/customization`,
      { data },
      { "Content-Type": "application/json", "Cache-Control": "no-cache" }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));

export { generate3dImage };
