<template>
  <div class="canvas-component" :style="canvasStyle">
    <prisma-controls
      v-if="
        $store && $store.state.enabledControls && $store.state.currentElement
      "
    />
    <PatternMode
      :elements="patternElements"
      :mode="patternModeNumber"
      :columns="patternsColumnsCount"
      v-if="$store.state.patternModeActive"
    />
    <template v-else v-for="(element, i) in customization.elements">
      <element-image
        v-if="element.jsClass === 'ElementImageModel'"
        :key="i"
        :element="element"
        :preview-context="previewContext"
      />
      <element-text
        v-else-if="element.jsClass === 'ElementTextModel'"
        :key="i * 3"
        :element="element"
        :preview-context="previewContext"
        :showTextBorder="showTextBorder"
      />
    </template>
    <component-logo
      v-if="customization.showLogo"
      :customization="customization"
      :preview-context="previewContext"
    />
  </div>
</template>

<script>
import ElementImage from "./element-image/ElementImage.vue";
import ElementText from "./element-text/ElementText.vue";
import ComponentLogo from "./component-logo/ComponentLogo.vue";
import prismaControls from "../../mixins/prisma-controls.js";
import PrismaControls from "../../components/prisma-controls/PrismaControls.vue";
import PatternMode from "../../components/pattern-mode/PatternMode.vue";

export default {
  components: {
    ElementImage,
    ElementText,
    ComponentLogo,
    PrismaControls,
    PatternMode,
  },
  props: { canvas: Object },
  mixins: [prismaControls],
  data() {
    return { available: false };
  },
  computed: {
    patternsColumnsCount() {
      const href = window.location.href;
      const url = new URL(href);
      const columns = url.searchParams.get("columns") || 4;
      return Number(columns);
    },
    patternElements() {
      return this.customization.elements.filter(
        (el) =>
          el.parameterCode.match("photo") || el.parameterCode.match("layer")
      );
    },
    patternModeNumber() {
      const href = window.location.href;
      const url = new URL(href);
      const pattern = url.searchParams.get("pattern") || 1;
      return Number(pattern);
    },
    showTextBorder() {
      return this.canvas.showTextBorder;
    },
    customization() {
      return this.canvas.customization;
    },
    canvasContext() {
      return this.canvas.customization.canvasContext;
    },
    scale() {
      return {
        x: this.canvas.previewContext.width / this.canvasContext.width,
        y: this.canvas.previewContext.height / this.canvasContext.height,
      };
    },
    previewContext() {
      return this.canvasContext;
    },
    canvasStyle() {
      const { canvasContext, scale } = this;
      return {
        width: `${canvasContext.width}px`,
        height: `${canvasContext.height}px`,
        transform: `scale(${scale.x}, ${scale.y})`,
        transformOrigin: `top left`,
      };
    },
  },
  methods: {
    validateText() {
      const clone = JSON.parse(JSON.stringify(this.customization));
      clone.elements.forEach((e) => {
        if (e.jsClass === "ElementTextModel")
          e.value = e.value.replace(/(^[\s]+|[\s]+$)/g, "");
      });
      return clone;
    },
    exportStage(callback) {
      const customization = this.validateText();
      setTimeout(() => {
        callback({
          customization,
          version: customization.timestamp,
          hasUploadFile: !!customization.elements
            .filter((el) => el.jsClass === "ElementImageModel")
            .find((el) => !!el.components.find((comp) => comp.canUploadFile)),
        });
      }, 100);
    },
    loadFont({ identifier, src }) {
      const chargeFont = new window.FontFace(identifier, `url(${src})`);
      return chargeFont.load().then((loaded) => document.fonts.add(loaded));
    },
  },
  mounted() {
    this.$emit("canvas", { name: "beforeExport", value: this.exportStage });
    if (this.$store && this.$store.state.enabledControls) this.enableControls();
  },
};
</script>

<style lang="scss" scoped>
.canvas-component {
  position: relative;
}
</style>
