<template>
  <svg :style="logoStyle" :width="width" :height="height">
    <path
      :fill="logoColor.stroke"
      d="M57.2,7.6c0-1.8-0.6-3.5-1.8-4.8c-1.3-1.4-2.9-2.1-4.8-2.1c-1.7,0-3.1,0.5-4.3,1.4c-1-0.9-2.4-1.4-3.9-1.4c-1,0-2,0.2-2.8,0.7C39.1,1.2,38.6,1,38.1,1h-1.8c-0.3,0-0.5,0-0.8,0.1c-0.6-0.2-1.3-0.3-2.1-0.3c-1.5,0-2.9,0.5-4,1.4c-1.1-0.9-2.6-1.4-4.2-1.4c-1.8,0-3.4,0.6-4.6,1.7c-1.3-1.1-2.8-1.7-4.6-1.7c-1,0-2,0.2-2.9,0.6C12.8,0.6,11.9,0,10.9,0H9.2C8.4,0,7.8,0.3,7.3,0.8l-0.4,0C5,0.8,3.3,1.5,2,2.8c-1.3,1.3-2,3-2,4.9c0,1.4,0.4,2.8,1.2,3.9C1,11.7,0.9,11.8,0.7,12C0.2,12.5,0,13.2,0,13.9c0.1,3.5,3,6.1,6.8,6.1c3.8,0,6.6-2.5,6.8-5.9c0.8,0.3,1.6,0.5,2.5,0.5c1.7,0,3.3-0.6,4.6-1.7c1.3,1.1,2.8,1.7,4.6,1.7c1.5,0,3-0.5,4.2-1.4c1.2,0.9,2.5,1.4,4,1.4c0.7,0,1.4-0.1,2.1-0.3c0.2,0.1,0.5,0.1,0.8,0.1h1.8c0.5,0,1-0.2,1.4-0.4c0.9,0.4,1.8,0.6,2.9,0.6c1.4,0,2.7-0.4,3.8-1.2c0.1-0.1,0.2-0.2,0.3-0.2c1.2,0.9,2.6,1.3,4.3,1.3c2.4,0,4.4-1,5.7-2.9c0.4-0.6,0.5-1.2,0.4-1.9C57,9.4,57.1,9,57.1,8.7C57.2,8.3,57.2,7.9,57.2,7.6z M16.1,7.6C16.1,7.6,16.1,7.6,16.1,7.6L16.1,7.6C16.1,7.6,16.1,7.6,16.1,7.6z"
    />
    <path
      :fill="logoColor.fill"
      d="M9.6,11L9.6,11c0.2-0.1,0.2-0.2,0.3-0.3c0.8-0.8,1.2-1.8,1.2-3c0-0.7-0.1-1.3-0.4-1.9L9.2,6.6c0.1,0.3,0.2,0.7,0.2,1c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.3-0.2-1.8-0.7C4.6,9,4.3,8.4,4.3,7.7c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.8-0.7v0h1.5c0.2,0,0.9,0,1.6-0.4l0,0c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.8-0.9,0.8-1.9H9.2c0,0.3-0.1,0.5-0.2,0.7C8.8,3.4,8.5,3.4,8.4,3.4l-1.6,0v0c-1.2,0-2.2,0.4-3,1.2C3,5.5,2.6,6.5,2.6,7.7c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.8,1.2,3,1.2c0.3,0,0.6,0,0.9-0.1c0.8,0.2,1.5,0.8,1.5,1.8c0,1.2-0.9,2-2.4,2c-1.3,0-2.3-0.8-2.4-1.9H2.6c0.1,2.1,1.8,3.6,4.3,3.6c2.5,0,4.2-1.5,4.2-3.7C11,12.4,10.6,11.6,9.6,11z"
    />
    <path
      :fill="logoColor.fill"
      d="M16.1,3.4c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.8,1.2,3,1.2c1.2,0,2.2-0.4,3-1.2c0.8-0.8,1.2-1.8,1.2-3c0-1.2-0.4-2.2-1.2-3C18.3,3.8,17.3,3.4,16.1,3.4z M17.9,9.5c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8c0-0.7,0.2-1.3,0.7-1.8s1.1-0.7,1.8-0.7c0.7,0,1.3,0.2,1.8,0.7s0.7,1.1,0.7,1.8C18.6,8.4,18.4,9,17.9,9.5z"
    />
    <path
      :fill="logoColor.fill"
      d="M25.3,10.2c-0.7,0-1.3-0.2-1.8-0.7C23,9,22.8,8.4,22.8,7.6c0-0.7,0.2-1.3,0.7-1.8c0.5-0.5,1.1-0.7,1.8-0.7c1,0,1.8,0.5,2.1,1.2l1.5-0.9c-0.6-1.3-2-2.1-3.6-2.1c-1.2,0-2.2,0.4-3.1,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.8,1.2,3.1,1.2c1.6,0,2.9-0.8,3.6-2.1l-1.5-0.9C27,9.7,26.2,10.2,25.3,10.2z"
    />
    <path
      :fill="logoColor.fill"
      d="M36.3,4.7c-0.7-0.9-1.6-1.4-2.8-1.4c-1.1,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.2,0.4,2.2,1.2,3c0.8,0.8,1.7,1.2,2.9,1.2c1.2,0,2.2-0.5,2.8-1.4v1.2h1.8V3.6h-1.8V4.7z M35.6,9.5c-0.5,0.5-1.1,0.7-1.8,0.7c-0.7,0-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.9c0-0.7,0.2-1.4,0.7-1.8C32.4,5.3,33,5,33.8,5c0.7,0,1.3,0.2,1.8,0.7c0.5,0.5,0.7,1.1,0.7,1.8C36.3,8.4,36.1,9,35.6,9.5z"
    />
    <path
      :fill="logoColor.fill"
      d="M42.7,6.9c-0.8-0.2-1.5-0.5-1.5-1.1c0-0.6,0.5-0.9,1.2-0.9c0.7,0,1.2,0.3,1.5,0.9l1.5-0.8c-0.6-1.1-1.7-1.7-3-1.7c-0.8,0-1.6,0.2-2.1,0.7c-0.6,0.5-0.8,1.1-0.8,1.8c0,1.7,1.6,2.2,2.8,2.5c0.9,0.2,1.5,0.5,1.5,1c0,0.6-0.5,0.9-1.4,0.9c-0.9,0-1.5-0.4-1.8-1.2L39.1,10c0.5,1.2,1.7,1.9,3.3,1.9c0.9,0,1.7-0.2,2.3-0.7c0.6-0.4,0.9-1.1,0.9-1.8C45.6,7.7,44,7.2,42.7,6.9z"
    />
    <path
      :fill="logoColor.fill"
      d="M54.6,7.6c0-1.2-0.4-2.2-1.2-3c-0.8-0.8-1.7-1.2-2.9-1.2c-1.2,0-2.3,0.4-3.1,1.2c-0.8,0.8-1.2,1.8-1.2,3c0,1.2,0.4,2.3,1.2,3.1c0.8,0.8,1.9,1.2,3.2,1.2c1.6,0,2.8-0.6,3.5-1.8l-1.4-0.8c-0.5,0.7-1.2,1-2.1,1c-1.3,0-2.3-0.7-2.6-1.9h6.5C54.6,8,54.6,7.8,54.6,7.6z M48.1,6.9c0.2-1.2,1.2-2,2.4-2c1.2,0,2.1,0.7,2.3,2H48.1z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    customization: Object,
    previewContext: Object,
  },
  data() {
    return {
      width: 57,
      height: 20,
    };
  },
  computed: {
    logoColor() {
      let element, component, image;
      const { customization } = this;

      element = this.customization.elements.find((el) => el.canDefineLogo);
      if (element)
        component = element.components.find((co) => co.canDefineLogo);
      if (component)
        image = component.images.find((im) => im.active && im.show);

      if (!image) return { fill: "#000", stroke: "#fff" };

      const logo_color =
        customization.logo.applies_color &&
        customization.logo.color !== "" &&
        !!customization.logo.color
          ? customization.logo.color
          : image.logoColor;

      const logo_stroke =
        image.logoStroke !== "" && !!image.logoStroke
          ? image.logoStroke
          : "transparent";

      const stroke =
        customization.logo.applies_border_color &&
        customization.logo.border_color !== "" &&
        !!customization.logo.border_color
          ? customization.logo.border_color
          : logo_stroke;

      return { fill: logo_color, stroke };
    },
    logoStyle() {
      const { previewContext, width, height, customization } = this;

      const left = Math.ceil(customization.logo.x - width / 2);
      const top = Math.ceil(customization.logo.y - height / 2);

      return {
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
        transform: `scale(${
          (previewContext.width / width) * (230 / previewContext.width)
        })`,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
